import styled from 'styled-components';
import { branding } from '../../../constants/info';

export const ContainerStyled = styled.button`
color: rgb(255,255,255);
display: -webkit-inline-box;
display: -webkit-inline-flex;
display: -ms-inline-flexbox;
display: -webkit-inline-box;
display: -webkit-inline-flex;
display: -ms-inline-flexbox;
display: inline-flex;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-webkit-box-pack: center;
-ms-flex-pack: center;
-webkit-justify-content: center;
-webkit-box-pack: center;
-webkit-justify-content: center;
-ms-flex-pack: center;
justify-content: center;
position: relative;
box-sizing: border-box;
-webkit-tap-highlight-color: transparent;
background-color: transparent;
outline: 0;
border: 0;
border-radius: 0;
padding: 1px 5px;
cursor: pointer;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
vertical-align: middle;
-moz-appearance: none;
-webkit-appearance: none;
-webkit-text-decoration: none;
-webkit-text-decoration: none;
text-decoration: none;
color: inherit;
font-family: "Roboto","Helvetica","Arial",sans-serif;
font-size: 0.875rem;
font-weight: 700;
line-height: 1.5;
text-transform: uppercase;
-webkit-letter-spacing: 0.02857em;
-moz-letter-spacing: 0.02857em;
-ms-letter-spacing: 0.02857em;
letter-spacing: 0.02857em;
min-width: 64px;
border-radius: 4px;
-webkit-transition: background-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,box-shadow 250ms cubic-bezier(0.4,0,0.2,1) 0ms,border-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,color 250ms cubic-bezier(0.4,0,0.2,1) 0ms;
-webkit-transition: background-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,box-shadow 250ms cubic-bezier(0.4,0,0.2,1) 0ms,border-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,color 250ms cubic-bezier(0.4,0,0.2,1) 0ms;
transition: background-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,box-shadow 250ms cubic-bezier(0.4,0,0.2,1) 0ms,border-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,color 250ms cubic-bezier(0.4,0,0.2,1) 0ms;
color: #fff;
background-color: #cb0c9f;
box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
width: ${props => props.width ? props.width : '100%'};
display: -webkit-inline-box;
display: -webkit-inline-flex;
display: -ms-inline-flexbox;
display: -webkit-inline-box;
display: -webkit-inline-flex;
display: -ms-inline-flexbox;
display: inline-flex;
-webkit-box-pack: center;
-ms-flex-pack: center;
-webkit-justify-content: center;
-webkit-box-pack: center;
-webkit-justify-content: center;
-ms-flex-pack: center;
justify-content: center;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
font-size: 0.75rem;
font-weight: 700;
border-radius: 0.5rem;
line-height: 1.4;
text-align: center;
text-transform: uppercase;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
-webkit-background-size: 150%!important;
background-size: 150%!important;
background-position-x: 25%!important;
-webkit-transition: all 150ms ease-in;
-webkit-transition: all 150ms ease-in;
transition: all 150ms ease-in;
background-color: #ffffff;
min-height: 2.5rem;
color: #fff;
box-shadow: 0rem 0.25rem 0.4375rem -0.0625rem rgb(0 0 0 / 11%), 0rem 0.125rem 0.25rem -0.0625rem rgb(0 0 0 / 7%);
background-color: ${branding.primaryColor};
margin: 5px;
background-image: linear-gradient( 
    310deg,
    ${branding.primaryColor},
    ${branding.primaryBackgroundColor}
);
`;