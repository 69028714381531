import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Formik, FieldArray } from 'formik';
import * as yup from 'yup';
import { MainForm } from './style';
import { useModal } from '../../../utils/useModal';
import { ApiService } from '../../../services'
import { PageTitle } from '../../molecules/page-title';
import { FormikInput } from '../../molecules/formik-input-field';
import { FormikSelected } from '../../molecules/formik-selected';
import { AtomicButton } from '../../atoms/button';
import { getUserData } from '../../../store/auth/auth.selectors'

export const MovementCreatePage = (props) => {
    const [show, onToggle, ContainerModalDialog] = useModal(false);
    const [message, setMessage ] = useState(null);
    const history = useHistory();
    const params = useParams();
    const { id } = useSelector(getUserData);
    
    const createPurchase = async (body) => {
        try{
            const { data } = await ApiService.StockService.createMovement(body);
            console.log(data);
            setMessage(data);
            onToggle();
        } catch (err) {
            console.log(err.response);
            setMessage(err.response);
            onToggle();
        }
    };
    if(params.type === 'Ajuste'){
        console.log(props.location.state)
    }
    return (
        <div>
            <div style={{ width: '100px' }}>
                <AtomicButton onClick={() => history.goBack()}>Atrás</AtomicButton>
            </div>
            <br />
            <PageTitle title={`Formulario de ${params.type}:`} />
            <Formik
                initialValues={{
                    type: params.type,
                    origin: id,
                    destination: id,
                    project: id,
                    obs:'',
                    stock: [
                        {
                            ref: params.type === 'Ajuste' ? props.location.state.ref : '',
                            num: params.type === 'Ajuste' ? props.location.state.num : '',
                            bach: params.type === 'Ajuste' ? props.location.state.bach : '',
                            width: params.type === 'Ajuste' ? props.location.state.width : 0,
                            length: params.type === 'Ajuste' ? props.location.state.length : 0,
                            totalInicial: params.type === 'Ajuste' ? props.location.state.totalInicial : 0,
                            total: params.type === 'Ajuste' ? props.location.state.total : 0,
                            reservado:  params.type === 'Ajuste' ? props.location.state.reservado : 0,
                            disponible: params.type === 'Ajuste' ? props.location.state.disponible : 0,
                            ubicacion: params.type === 'Ajuste' ? props.location.state.ubicacion : '',
                            obs: params.type === 'Ajuste' ? props.location.state.obs : '',
                            movimientos: params.type === 'Ajuste' ? props.location.state.movimientos : [],
                            inventorycheck: params.type === 'Ajuste' ? props.location.state.inventorycheck : []
                        }
                    ]
                }}
                onSubmit={(data) => {
                    createPurchase(data); // 
                }}
                validationSchema={yup.object().shape({
                    type: yup.string().required('Campo obligatorio'),
                    stock: yup.array()
                    .of(
                      yup.object().shape({
                        ref: yup.string().required('Campo obligatorio'),
                        num: yup.string().required('Campo obligatorio'),
                        total: yup.number().required('Campo obligatorio'),
                      })
                    )
                })}
                >
                {({ handleSubmit, values, errors }) => (
                    <MainForm onSubmit={handleSubmit} noValidate>
                        {params.type !== 'Ajuste' ? 
                        (<FormikInput type="text" label="Observaciones" name="obs" />)
                        :
                        (<FormikSelected 
                            label="Observaciones"
                            name="obs"
                            options={
                                [
                                    {value: 'Cambio de bodega', label: 'Cambio de bodega' },
                                    {value: 'Ajuste de Inventario', label: 'Ajuste de Inventario' }
                                ]
                            }
                        /> )}
                        <FieldArray
                            name="stock"
                            render={arrayHelpers => (
                            <>
                                {values.stock && values.stock.length > 0 ? (
                                values.stock.map((friend, index) => (
                                    <div style={{ width: '100%' }} key={index}>
                                    <label>{`Item: ${index + 1}`}</label>
                                    {params.type !== 'Ajuste' ? 
                                    (<>
                                        <FormikInput type="text" name={`stock.${index}.ref`} label="Referencia" />
                                        <FormikInput type="text" name={`stock.${index}.num`} label="Número" />
                                        <FormikInput type="text" name={`stock.${index}.bach`} label="Bach (Si aplica)" />
                                        <FormikInput type="number" name={`stock.${index}.width`} label="Ancho" />
                                        <FormikInput type="number" name={`stock.${index}.length`} label="Largo" />
                                        <FormikInput type="number" name={`stock.${index}.totalInicial`} label="Total Inicial" />
                                        <FormikInput type="number" name={`stock.${index}.total`} label="Total" />
                                        <FormikInput type="number" name={`stock.${index}.reservado`} label="Reservado" />
                                        <FormikInput type="number" name={`stock.${index}.disponible`} label="Disponible" />
                                    </>)
                                    : 
                                    values.obs === 'Ajuste de Inventario' && (
                                    <>
                                        <FormikInput type="text" name={`stock.${index}.ref`} label="Referencia" />
                                        <FormikInput type="text" name={`stock.${index}.num`} label="Número" />
                                        <FormikInput type="text" name={`stock.${index}.bach`} label="Bach (Si aplica)" />
                                        <FormikInput type="number" name={`stock.${index}.width`} label="Ancho" />
                                        <FormikInput type="number" name={`stock.${index}.length`} label="Largo" />
                                        <FormikInput type="number" name={`stock.${index}.totalInicial`} label="Total Inicial" />
                                        <FormikInput type="number" name={`stock.${index}.total`} label="Total" />
                                        <FormikInput type="number" name={`stock.${index}.reservado`} label="Reservado" />
                                        <FormikInput type="number" name={`stock.${index}.disponible`} label="Disponible" />
                                    </>)}
                                    {params.type !== 'Ajuste' ?
                                    (<FormikInput type="text" name={`stock.${index}.ubicacion`} label="Ubicación" />)
                                    :
                                    values.obs === 'Cambio de bodega' && (<FormikInput type="text" name={`stock.${index}.ubicacion`} label="Ubicación" />)}
                                    <FormikInput type="text" label="Observaciones" name={`stock.${index}.obs`} />
                                    <div style={{ display: 'flex'}}>
                                        <AtomicButton
                                            type="button"
                                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                        >
                                            Quitar Item
                                        </AtomicButton>
                                        <AtomicButton
                                            type="button"
                                            onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                        >
                                            Nuevo Item
                                        </AtomicButton>
                                    </div>
                                    </div>
                                ))
                                ) : (
                                <AtomicButton type="button" onClick={() => arrayHelpers.push('')}>
                                    {/* show this when user has removed all friends from the list */}
                                    Agregar Item
                                </AtomicButton>
                                )}
                            </>
                            )}
                        />
                        <div>
                            <AtomicButton onClick={handleSubmit}>Crear Item</AtomicButton>
                        </div>
                    </MainForm>
                )}
            </Formik>
            {show && (
            <ContainerModalDialog
                title={message?.code === 200 ? 'Exito' : 'Error'}
            >
                <p>{message?.code === 200 ? `Se creo exitosamente la ${params.type}`: message?.data?.message}</p>
            </ContainerModalDialog>
            )}
        </div>
    )
};
