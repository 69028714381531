import { ConfigPage } from '../components/pages/config';
import { Home } from '../components/pages/home';
import { MePage } from '../components/pages/me-page'
import { Ventas } from '../components/pages/ventas';
import { Logistics } from '../components/pages/logistics';
import { Finance } from '../components/pages/finance';
import { Tecnico } from '../components/pages/tecnico';
import { ShipmentsDetail } from '../components/pages/shipments-detail';
import { TranstacionsPage } from '../components/pages/transactions';
import { ProductsPage } from '../components/pages/products-page';
import { ProductDetailPage } from '../components/pages/product-detail';
import { Taxes } from '../components/pages/taxes';
// Stock
import { Inventario } from '../components/pages/stock';
import { StockRefDetailPage } from '../components/pages/stock-detail';
import { StockEditPage } from '../components/pages/stock-edit-reference';
import { StockCreatePage } from '../components/pages/stock-create-reference';
import { ItemSearchPage } from '../components/pages/item-search';
import { MovementCreatePage } from '../components/pages/item-create';
// Purchase
import { PurchaseDetailPage } from '../components/pages/purchase-detail';
import { PurchaseCreatePage } from '../components/pages/purchase-create';
import { PurchaseUpdateProgressPage } from '../components/pages/purchase-update-progress';
import { PurchaseUpdatePage } from '../components/pages/purchase-update';
import { PurchaseOCPage } from '../components/pages/purchase-oc';
// Transport
import { TransportDetailPage } from '../components/pages/transport-detail';
import { TransportCreatePage } from '../components/pages/transport-create';
import { TransportUpdatePage } from '../components/pages/transport-update';
import { TransportUpdateProgressPage } from '../components/pages/transport-update-progress';
// Projects
import { ProjectsPage } from '../components/pages/projects';
import { ProjectDetailPage } from '../components/pages/project-detail';
import { ProjectCreatePage } from '../components/pages/project-create';

export const privateListRoutes = [
  {
    path: '/',
    authPage: '/login',
    component: Home,
  },
  {
    path: '/me',
    authPage: '/login',
    component: MePage,
  },
  {
    path: '/transport/:id',
    authPage: '/login',
    component: TransportDetailPage,
  },
  {
    path: '/nuevo-transport',
    authPage: '/login',
    component: TransportCreatePage,
  },
  {
    path: '/transport-update-progress/:id',
    authPage: '/login',
    component: TransportUpdateProgressPage,
  },
  {
    path: '/transport-update/:id',
    authPage: '/login',
    component: TransportUpdatePage,
  },
  {
    path: '/purchase/:id',
    authPage: '/login',
    component: PurchaseDetailPage,
  },
  {
    path: '/create-purchase',
    authPage: '/login',
    component: PurchaseCreatePage,
  },
  {
    path: '/purchase-update-progress/:id',
    authPage: '/login',
    component: PurchaseUpdateProgressPage,
  },
  {
    path: '/purchase-update/:id',
    authPage: '/login',
    component: PurchaseUpdatePage,
  },
  {
    path: '/purchase-print/:id',
    authPage: '/login',
    component: PurchaseOCPage,
  },
  {
    path: '/stock',
    authPage: '/login',
    component: Inventario
  },
  {
    path: '/stock-ref/:id',
    authPage: '/login',
    component: StockRefDetailPage,
  },
  {
    path: '/item-search',
    authPage: '/login',
    component: ItemSearchPage,
  },
  {
    path: '/movement-create/:type',
    authPage: '/login',
    component: MovementCreatePage,
  },
  {
    path: '/stock-create-ref',
    authPage: '/login',
    component: StockCreatePage,
  },
  {
    path: '/stock-edit-ref',
    authPage: '/login',
    component: StockEditPage,
  },
  {
    path: '/proyectos',
    authPage: '/login',
    component: ProjectsPage
  },
  {
    path: '/proyecto/:id',
    authPage: '/login',
    component: ProjectDetailPage
  },
  {
    path: '/nuevo-proyecto',
    authPage: '/login',
    component: ProjectCreatePage
  },
  {
    path: '/wrap-details',
    authPage: '/login',
    component: ShipmentsDetail
  },
  {
    path: '/sales',
    authPage: '/login',
    component: Ventas
  },
  {
    path: '/logistics',
    authPage: '/login',
    component: Logistics
  },
  {
    path: '/tecnico',
    authPage: '/login',
    component: Tecnico
  },
  {
    path: '/transactions/:id?',
    authPage: '/login',
    component: TranstacionsPage,
  },
  {
    path: '/finance',
    authPage: '/login',
    component: Finance,
  },
  {
    path: '/taxes',
    authPage: '/login',
    component: Taxes,
  },
  {
    path: '/productos',
    authPage: '/login',
    component: ProductsPage,
  },
  {
    path: '/productos/:id',
    authPage: '/login',
    component: ProductDetailPage,
  },
  {
    path: '/config',
    authPage: '/login',
    component: ConfigPage,
  },
  {
    path: '/transactions',
    authPage: '/login',
    component: ConfigPage,
  },
];
