import styled from 'styled-components';

export const StyledIconMainButton = styled.button`
    border: 1px solid #4054B2;
    border-radius: 8px;
    position: relative;
    background: #4054B2;
    margin: 5px;
    min-width: 115px;
    padding: 5px 5px;
    color: #fff;
    & > h1{
        color: #fff;
    }
    & > img {
        color: #fff;
    }
    &:hover {
        color: #FFEA33;
        overflow: hidden;
        box-shadow: 0 0 25px rgb(0 0 0 / 5%);
        background: #fff;
        border: 1px solid #FFEA33;
        & > h1{
            color: #FFEA33;
        }
        & > img {
            color: #FFEA33;
        }
    }

`;


