import styled from 'styled-components';
import { Menu } from 'antd';
import { branding } from '../../../constants/info';

export const StyledMenuItem = styled(Menu)`
    .ant-menu-item {
        display: flex;
        flex-direction: row;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        position: relative;
        -webkit-text-decoration: none;
        text-decoration: none;
        width: 100%;
        box-sizing: border-box;
        text-align: left;
        padding: 5px 8px;
    }
    .ant-menu-title-content{
        display: flex;
        justify-content: center;
        align-items: baseline;
    }
    .ant-menu-item-selected{
        color: rgb(52, 71, 103);
        font-weight: 500;
        line-height: 0;
        width: 100%;
        cursor: pointer;
        margin: 0 1rem;
        display: flex;
        box-shadow: none;
        align-items: center;
        user-select: none;
        white-space: nowrap;
        border-radius: 0.5rem;

        -webkit-transition: all 150ms ease-in;
        transition: all 150ms ease-in;
        box-shadow: rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem 0rem;
        & > span > div {
            background: ${branding.primaryColor};
        }
        & > span > div > span > svg {
            /*  */
            color: #fff;
        }
    }
`;

export const StyledIconDiv = styled.div`
    background: #fff;
    display: flex;
    min-width: 2rem;
    box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgb(20 20 20 / 12%), 0rem 0.125rem 0.25rem -0.0625rem rgb(20 20 20 / 7%);
    min-height: 2rem;
    transition: margin 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    place-items: center;
    border-radius: 0.5rem;
    margin: 20px;
    justify-content: center;
    color: rgb(58, 65, 111);;
`;