import React from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import { MainForm } from './style';
import { ApiService } from '../../../services'
import { PageTitle } from '../../molecules/page-title';
import { FormikInput } from '../../molecules/formik-input-field';
import { AtomicButton } from '../../atoms/button';

export const StockEditPage = (props) => {
    const history = useHistory();
    console.log('stock-edit', props.location.state);
    if (!props.location.state) history.push('/stock');
    return (
        <div>
            <div style={{ width: '100px' }}>
                <AtomicButton onClick={() => history.goBack()}>Atrás</AtomicButton>
            </div>
            <br />
            <PageTitle title={`Editar de referencia ${props.location.state.ref}-${props.location.state.name}`} />
            <Formik
                initialValues={{}}
                onSubmit={async (body) => {
                    console.log(body);
                     try {
                        const { data } = await ApiService.StockService.editStockRef(props.location.state.id, body);
                        // console.log(data);
                        if(data.code === 200) history.push(`/stock-ref/${data.data.stock.id}`)
                    } catch (err){
                        alert('Error hablar con soporte');
                        console.log(err.response);
                    }
                }}
                validationSchema={yup.object().shape({
                    total: yup.number(),
                    reservado: yup.number(),
                    disponible: yup.number(),
                    tipo: yup.string(),
                    collecion: yup.string(),
                    soldaduraTotal: yup.number(),
                    soldaduraReservada: yup.number(),
                    soldaduraDisponible: yup.number(),
                    refSoldadura: yup.string().nullable(),
                    floorImg: yup.string(),
                    unidad: yup.string(),
                    presentacion: yup.string(),
                    rendimiento: yup.number(),
                    peso: yup.number(),
                    value: yup.number(),
                    obs: yup.string().nullable(),
                    stock: yup.array(),
                    movimientos: yup.array(),
                })}
                >
                {({ handleSubmit }) => (
                    <MainForm onSubmit={handleSubmit} noValidate>
                        <FormikInput type="number" label={`Total: (Actual: ${props.location.state.total})`} name="total" />
                        <FormikInput type="number" label={`Reservado: (Actual: ${props.location.state.reservado})`} name="reservado" />
                        <FormikInput type="number" label={`Disponible: (Actual: ${props.location.state.disponible})`} name="disponible" />
                        <FormikInput type="text" label={`Tipo: (Actual: ${props.location.state.tipo})`} name="tipo" />
                        <FormikInput type="text" label={`Colleción: (Actual: ${props.location.state.collecion})`} name="collecion" />
                        <FormikInput type="number" label={`Soldadura total (Si aplica. Actual: ${props.location.state.soldaduraTotal})`} name="soldaduraTotal" />
                        <FormikInput type="number" label={`Soldadura reservada (Si aplica. Actual: ${props.location.state.soldaduraReservada})`} name="soldaduraReservada" />
                        <FormikInput type="number" label={`Soldadura disponible (Si aplica. Actual: ${props.location.state.soldaduraDisponible})`} name="soldaduraDisponible" />
                        <FormikInput type="text" label={`Ref de soldadura (Si aplica. Actual: ${props.location.state.refSoldadura || 'No Aplica'})`} name="refSoldadura" />
                        <FormikInput type="text" label={`Link de imagen: (Actual: ${props.location.state.floorImg})`} name="floorImg" />
                        <FormikInput type="text" label={`Unidad de medida: (Actual: ${props.location.state.unidad})`} name="unidad" />
                        <FormikInput type="text" label={`Presentación de referencia: (Actual: ${props.location.state.presentacion})`} name="presentacion" />
                        <FormikInput type="number" label={`Rendimiento x unidad: (Actual: ${props.location.state.rendimiento})`} name="rendimiento" />
                        <FormikInput type="number" label={`Peso x unidad: (Actual: ${props.location.state.peso})`} name="peso" />
                        {false && <FormikInput type="number" label={`Valor x unidad: (Actual: ${props.location.state.value})`} name="value" />}
                        <FormikInput type="text" label={`Observaciones de ref: ${props.location.state.obs || 'Sin observaciones.'}`} name="obs" />
                        <div>
                            <AtomicButton 
                                type="button" 
                                onClick={handleSubmit}
                            >
                                Editar referencia
                            </AtomicButton>
                        </div>
                    </MainForm>
                )}
            </Formik>
        </div>
    )
};
