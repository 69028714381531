import React, { useState } from 'react';
import { Input } from 'antd';
import { StyledTable } from './style';

export const TableCustom = ({ columns, data = [], searchablePlaceholder, serachableObjKey, searchableTable = false }) => {
  const [dataSource, setDataSource] = useState(data);
  const [value, setValue] = useState('');

  const FilterByNameInput = (
    <Input
      placeholder={searchablePlaceholder}
      value={value}
      onChange={e => {
        const currValue = e.target.value;
        setValue(currValue);
        const filteredData = data.filter(entry =>
          entry[serachableObjKey].includes(currValue)
        );
        setDataSource(filteredData);
      }}
    />
  );

  const columns2 = [
    {
      title: FilterByNameInput,
      dataIndex: serachableObjKey,
      key: '1'
    },
    ...columns
  ];

  const dataSorter = columns2.map((item) => {
    const sorter = (a, b) => a[item.dataIndex] - b[item.dataIndex];
    return {
      sorter,
      ...item,
    };
  });
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  const dataSorterNoSearch = columns.map((item) => {
    const sorter = (a, b) => a[item.dataIndex] - b[item.dataIndex];
    return {
      sorter,
      ...item,
    };
  });
  const onChange1 = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };
  
  return (
    <StyledTable
      rowKey="id"
      columns={searchableTable ? dataSorter : dataSorterNoSearch}
      dataSource={dataSource}
      onChange={searchableTable ? onChange : onChange1} 
      pagination={{ position: [ 'bottomCenter'] }}
      size="sx"
      scroll={{ x: 'calc(700px + 50%)' }}
    />);
};
