import React from 'react';
import { useHistory } from 'react-router-dom';
import { TableCustom } from '../../organims/table';
import { WrapperShipments, MainCardIndicator } from './styled';
import { PageTitle } from '../../molecules/page-title';
import { Tag, Space, Button, Row,  Col } from 'antd';
import { projectList } from '../../../constants/projectData';
import GeneralHomeFunctions from '../../organims/general-home-functions';
import { funcionalidades } from '../../../constants/funcionalidades';
import { branding } from '../../../constants/info';

export const Finance = () => {
  const history = useHistory();


  const columns = [
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company'
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      render: (text, record) => (
        <Tag color='blue'>
          {record.status.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button onClick={() => history.push('/wrap-details')}>Ver detalles</Button>
        </Space>
      ),
    },
  ];
  return (
    <WrapperShipments>
      <PageTitle  title="Contabilidad" />
      <Row gutter={16}>
      {indicators.map(indicator => (
        <Col flex="1 0 25%">
          <MainCardIndicator
            title={indicator.title}
            bordered={false}
          >
            <h1>{indicator.content}</h1>
          </MainCardIndicator>
      </Col>
      ))}
      </Row>
      <p>Aquí se pueden ver estadísticas y metricas del departamento de Contabilidad en {branding.brandName}</p>
      <GeneralHomeFunctions page="contabilidad" funcionalidades={funcionalidades} />
      <Row>
        <h1>Obras (Prospección - Finalizar Obra):</h1>{' '}
        <Button onClick={() => alert('Crear una salida del inventario')}>Crear projecto nuevo</Button>
      </Row>
      <TableCustom
        columns={columns} 
        data={projectList} 
        searchableTable={true}
        searchablePlaceholder="Obra"  
        serachableObjKey='name'
      />
      <Row>
        <h1>Reservas:</h1>{' '}
        <Button onClick={() => alert('Crear una reserva del inventario')}>Crear reserva nueva</Button>
      </Row>
      <TableCustom
        columns={columns} 
        data={projectList} 
        searchableTable={true}
        searchablePlaceholder="Obra"  
        serachableObjKey='name'
      />
      <Row>
        <h1>Entradas:</h1>
        <p>(Las entradas para el departamento comercial son informativos y no los pueden modificar.)</p>
      </Row>
      <TableCustom
        columns={columns} 
        data={projectList} 
        searchableTable={true}
        searchablePlaceholder="Obra"  
        serachableObjKey='name'
      />
      <Row>
        <h1>Salidas:</h1>
        <p>(Las salidas para el departamento comercial son informativos y no los pueden modificar.)</p>
      </Row>
      <TableCustom
        columns={columns} 
        data={projectList} 
        searchableTable={true}
        searchablePlaceholder="Obra"  
        serachableObjKey='name'
      />
      <Row>
        <h1>Facturas:</h1>{' '}
        <Button onClick={() => alert('Crear una reserva del inventario')}>Crear reserva nueva</Button>
      </Row>
      <TableCustom
        columns={columns} 
        data={projectList} 
        searchableTable={true}
        searchablePlaceholder="Obra"  
        serachableObjKey='name'
      />
    </WrapperShipments>
  );
};

const indicators = [
  {
    title: 'Prospectos',
    content: '150/Mes'
  },
  {
    title: 'Cotizaciones',
    content: '45/Mes'
  },
  {
    title: 'Projectos',
    content: '33/Mes'
  },
  {
    title: 'Facturación',
    content: '60MM/Mes'
  },
  {
    title: 'Cartera',
    content: '15MM/Mes'
  },
  {
    title: 'Comercial #1',
    content: 'Christian Prieto'
  }
];



