import { Table } from 'antd';
import { useHistory } from 'react-router-dom';
import { StyledTable } from './style';
import { movimiento } from '../../../constants/info';

export const  MovementTableOrganism = ({ inventory }) => {
  const history = useHistory()
  const expandedRowRender = ({ stock }) => {
    // console.log(stock);
    const columns = [
      { title: 'Numero de rollo', dataIndex: 'num', key: 'num' },
      { 
        title: 'Bach',
        dataIndex: 'bach',
        key: 'bach',
        render: (_, row ) => (
          <span>
            {`${row.itemId?.bach || 'N/A'}`}
          </span>
        ),
      },
      {
        title: 'Medidas',
        key: 'width',
        render: (_, row ) => (
          <span>
            {`${row.itemId?.width || 0}x${row.itemId?.length || 0}`}
          </span>
        ),
      },
      { title: 'Total', dataIndex: 'total', key: 'total' },
    ];
    return <Table columns={columns} dataSource={stock} pagination={false} />;
  };

  const columns = [
    { title: 'Movimiento de Inventario', dataIndex: 'movement_consec', key: 'movement_consec', render: (text, row, index) => {
        return (
          <div>
            <a href="/" onClick={() => history.push(`/stock-ref/${row.id || row.key}`)}>{text}</a>
            <p>{`${row.type} ${new Date(row.date).toLocaleDateString()}`}</p>
            <p>{`Entrega: ${row.origin.compnayName}`}</p>
            <p>{`Recibe: ${row.destination.compnayName}`}</p>
            <p>{`Observaciones: ${row.obs}`}</p>
          </div>
        )
    } },

  ];

  return (
    <StyledTable
      className="components-table-demo-nested"
      columns={columns}
      rowKey="id"
      expandable={{ expandedRowRender }}
      dataSource={inventory || movimiento}
    />
  );
}