import crypto from 'crypto';
import oauth1a from 'oauth-1.0a';
import axios from 'axios';

const CONSUMERKEY = 'ck_fb610b2dba5ced5530c3b914f301403aad5fc762';
const CONSUMERSECRET = 'cs_b77293db75338527834a5d6078d38d67a795ffc9';
const TOKENKEY = '';
const TOKENSECRET = '';

const request = {
  url: 'http://54.167.229.41/wp-json/wc/v3/products?per_page=15&include=23',
  method: 'GET'
};


class Oauth1Helper {
    static getAuthHeaderForRequest(request) {
        const oauth = oauth1a({
            consumer: { key: CONSUMERKEY, secret: CONSUMERSECRET },
            signature_method: 'HMAC-SHA1',
            hash_function(base_string, key) {
                return crypto
                    .createHmac('sha1', key)
                    .update(base_string)
                    .digest('base64')
            },
        })

        const authorization = oauth.authorize(request, {
            key: TOKENKEY,
            secret: TOKENSECRET,
        });

        return oauth.toHeader(authorization);
    }
}

export class WooService {
  constructor(client, awsClient) {
    this.client = client;
    this.awsClient = awsClient;
  }

  getProducts() {
    return this.client.get('/wp-json/wc/v3/products?per_page=15&include=10457,10468,10481,10239,10434'); // 
  }

  getAWSProducts() {
    const authHeader = Oauth1Helper.getAuthHeaderForRequest(request);
    return axios.get('http://54.167.229.41/wp-json/wc/v3/products?per_page=15&include=23', { headers: authHeader }); // 
  }

  getProductById(id){
    return this.client.get(`/wp-json/wc/v3/products/${id}`)
  }

  getProductVariations(id){
    return this.client.get(`/wp-json/wc/v3/products/${id}/variations`)
  }

  getOrdersByProductId(id) {
    return this.client.get(`/wp-json/wc/v3/orders?product=${id}`); // 
  }

}
