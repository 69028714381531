export class PurchaseService {
  constructor(client) {
    this.client = client;
  }

  healthCheck() {
    return this.client.get('/gastos/healthcheck');
  }

  getPurchases(){
    return this.client.get('/gastos/getPurchases');
  }
  
  getPurchaseById(id){
    return this.client.get(`/gastos/getPurchaseById?id=${id}`)
  }
  
  createPurchase(body){
    return this.client.post(`/gastos/createPurchase`, body)
  }
  
  updatePurchase(id, body){
    return this.client.post(`/gastos/updatePurchase?id=${id}`, body)
  }
  
  updatePurchaseProgress(id, body){
    const bodyFormData = new FormData();
    bodyFormData.append('updatedBy', body.updatedBy);
    bodyFormData.append('title', body.title);
    bodyFormData.append('status', body.status);
    bodyFormData.append('description', body.description);
    body.file !== null && bodyFormData.append('file', body.file);
    return this.client.post(`/gastos/updatePurchaseProcess?id=${id}`, bodyFormData, {headers: { "Content-Type": "multipart/form-data" }})
  }
}
