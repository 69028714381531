import styled from 'styled-components';

export const StyledTitleDiv = styled.div`
    padding: 5px 10px;
`;

export const StyledHr = styled.hr`
  margin: 10px 0px;
  background-color: #67748E;
  opacity: 0.1;
`;