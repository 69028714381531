import styled from 'styled-components';
import { Card } from 'antd';

export const MainHome = styled.div`
    display: flex;
    flex-direction: column !important;
`;

export const MainCardIndicator = styled(Card)`
    display: flex;
    flex-direction: column !important;
    border: 1px solid blue;
    border-radius: 5px;
    align-items: center;
    margin: 2px 0 5px 0;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 1px 2px rgb(0 0 0 / 23%);
    & > .ant-card-head {
        min-height: 12px !important;
    }
    .ant-card-head-title {
        min-height: none;
        padding: 0 3px;
    }
    .ant-card-body{
        padding: 0 3px;
    }
`;