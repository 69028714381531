import React, { useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';
import { useDispatch/* , useSelector */ } from 'react-redux';
import { StockTableOrganism } from '../../organims/stock-table-organism';
import { WrapperShipments } from './styled';
import { PageTitle } from '../../molecules/page-title';
import { ApiService } from '../../../services';
// import { AtomicButton } from '../../atoms/button';
import { LoaderAtom } from '../../atoms/loading';
// import { getUserData } from '../../../store/auth/auth.selectors';

export const Inventario = () => {
  const [inventory, setInventory] = useState(null);
  // const history = useHistory();
  // const user = useSelector(getUserData);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: 'test',
      success: {
        id: 'custom',
      },
    });
    test()
  }, [dispatch]);
  const test = async () => {
    try {
      const { data } = await ApiService.StockService.getStock();
      // console.log(data);
      setInventory(data.data.stock);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <WrapperShipments>
      <PageTitle  title="Inventario" />
      {inventory ? (<StockTableOrganism inventory={inventory} />) : (<LoaderAtom />)}
    </WrapperShipments>
  );
};




