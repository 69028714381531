import React from 'react';
import { PageTitle } from '../../molecules/page-title';
import { MainHome } from './styled';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux'
import { MainCardIndicator } from '../../molecules/main-card-indicator';
import { GeneralUserMenu } from '../../organims/general-user-menu';
// import GeneralHomeFunctions from '../../organims/general-home-functions';
// import { MainTable } from '../../molecules/main-table';
import { funcionalidades } from '../../../constants/funcionalidades';
// the hook
import { useTranslation } from 'react-i18next';
import { getUserData } from '../../../store/auth/auth.selectors'

export const MePage = () => {
  const { t } = useTranslation();
  const user = useSelector(getUserData);
  const indicators = [
    {
      title: 'Pendientes',
      content: user.pending,
      crecimiento: -2,
      acendente: false,
      icon: 'ArrowDownOutlined'
    },
    {
      title: 'Vacaciones pendientes',
      content: user.vacations,
      crecimiento: -2,
      acendente: true,
      icon: 'ArrowUpOutlined'
    },
    {
      title: 'KPIS',
      content: `${user.kpis}`,
      crecimiento: 2,
      acendente: false,
      icon: 'ArrowUpOutlined'
    }
  ];
   return (
  <MainHome>
    <PageTitle title={`${t('Inicio')} ${user.firstName}`} />
    <div>
      <p>{`Nombres: ${user.firstName}, ${user.lastName}`}</p>
      <p>{`Puesto: ${user.title}, ${user.compnayName}`}</p>
      <p>{`Idioma: ${user.language === 'es' ? 'Español' : 'Sueco'}`}</p>
      <p>{`Correo: ${user.email}`}</p>
      <p>{`Celular: ${user.phone}`}</p>
      <p>{`Derechos: ${user.cliente ? 'Cliente ' : ''}${user.proveedor ? 'Proveedor ' : ''}${user.comercial ? 'Comercial ' : ''}${user.logistica ? 'Logistica ' : ''}${user.tecnico ? 'técnico ' : ''}${user.gerencia ? 'Gerencia '  : ''}${user.developer ? 'R&D ' : ''}`}</p>
    </div>
    {!user.cliente && !user.proveedor && (
    <>
      <Row gutter={16}>
        {indicators.map(indicator => (
          <Col flex="1 0 30%" key={indicator.title}>
            <MainCardIndicator
              bordered={false}
              indicator={indicator}
            />
        </Col>
        ))}
      </Row>
      <PageTitle title={t('UserFuncs')} />
      <GeneralUserMenu funcionalidades={funcionalidades} />
    </>
    )}

  </MainHome>
)};
