import React, { useState, useEffect } from 'react';
import { ApiService } from '../../../services';
import { useModal } from '../../../utils/useModal';
import { Formik } from 'formik';
import { StyledRefMainRowInnerImg } from './style';
import { useHistory } from 'react-router-dom';
import { PageTitle } from '../../molecules/page-title';
import { AtomicButton } from '../../atoms/button';
import { FormikInput } from '../../molecules/formik-input-field';
// import { LoaderAtom } from '../../atoms/loading';
// import { StockInnerTableOrganism } from '../../organims/stock-inner-table-organism';
// import { MovementTableOrganism } from '../../organims/movement-table-organism';
import { BarcodeScannerOrganism } from '../../organims/stock-item-search';
import { LoaderAtom } from '../../atoms/loading';
import { StockTableOrganism } from '../../organims/stock-table-organism';

export const ItemSearchPage = () => {
    const [show, onToggle, ContainerModalDialog] = useModal(false);
    const [byInventory, setByInventory] = useState(false);
    const [inventory, setInventory] = useState(null);
    const history = useHistory();
    const [ref, setRef] = useState(null);
    const [error, setError] = useState(null);
    const [scan, setScan ] = useState(true);
    const [found, setFound] = useState(false);
    const getInfo = async (num) => {
        if(num !== "Not Found"){
            try {
                const res = await ApiService.StockService.getItemByNum(num);
                console.log(res);
                setRef(res.data.data.item);
                setFound(true)
            } catch (err) {
                onToggle()
                setError(err.response);
            }
        }
    };
    const handleConfirm = async () => { 
        if(ref?.num !== "Not Found"){
            try {
                const res = await ApiService.StockService.updateVerifiedItemById(ref?.num);
                console.log(res);
                setFound(false)
            } catch (err) {
                onToggle()
                setError(err.response);
            }
        }
    }
    const getInventory = async () => {
        try {
          const { data } = await ApiService.StockService.getStock();
          // console.log(data);
          setInventory(data.data.stock);
        } catch (error) {
          console.log(error);
        }
      };
    useEffect(() => getInventory(), []);
    return (
        <div>
            <div style={{ width: '100px' }}>
                <AtomicButton onClick={() => history.goBack()}>Atrás</AtomicButton>
            </div>
            <br />
            <PageTitle title='Busqueda de rollos' />
            <AtomicButton onClick={() => setByInventory(!byInventory)}>{byInventory ? 'Inventario' : 'Busqueda por número'}</AtomicButton>
            {byInventory ? !found ? 
            <>
                <AtomicButton onClick={() => setScan(!scan)}>{scan ? 'Busqueda por número' : 'Escanear'}</AtomicButton>
                {scan ? <BarcodeScannerOrganism setData={getInfo} /> : (
                <Formik
                initialValues={{
                    num: '',
                }}
                onSubmit={async (values) => {
                    getInfo(values.num);
                }}
                >
                {({handleSubmit}) => (
                    <form>
                    <FormikInput
                        name="num"
                        style={{ width: '100%' }}
                        type="text"
                        placeholder="Número de inventario"
                    />
                    <AtomicButton onClick={handleSubmit}>Buscar</AtomicButton>
                    </form>
                )}
                </Formik>)}
            </>
            : ( 
            <div>
                <p>{`${ref?.ref}-${ref?.refId?.name}`}</p>
                <StyledRefMainRowInnerImg img={ref?.refId?.floorImg} />
                <p>{`Número: ${ref?.num}${ref?.bach && ` & Bach: ${ref?.bach}`}`}</p>
                <p>{`Total: ${ref?.total}`}</p>
                <AtomicButton onClick={handleConfirm}>Confirmar en inventario</AtomicButton>
                <AtomicButton onClick={() => alert('Editar rollo')}>Editar Rollo</AtomicButton>
                <AtomicButton onClick={() => setFound(false)}>Nueva busqueda</AtomicButton>
            </div>
            ) : inventory ? (<StockTableOrganism inventory={inventory} />) : (<LoaderAtom />)}
            {show && (
            <ContainerModalDialog
                title='Error'
            >
                {error?.data?.message}
            </ContainerModalDialog>
            )}
        </div>
    )
};
