import React from 'react';
import { Table, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { getUserData } from '../../../store/auth/auth.selectors'
import { PrintOrganism } from '../print-organism';
const { Text } = Typography;

export const ProjectEndorganism = (props) => {
    const user = useSelector(getUserData);
    return (
        <div>
            <PrintOrganism>
                <br />
                <h4>Liquidación movimiento de producto</h4>
                <Table
                    columns={columns}
                    dataSource={props.items || []}
                    pagination={false}
                    // bordered
                    summary={pageData => {
                        let totalamount = 0;
                        pageData.forEach(({ total, type, price }) => {
                            totalamount = (total * (type === "Entrada" ?  price : type === "Salida" ? -price : type === "completed" ? -price : 0)) + totalamount;
                        });
                        return (
                        <>
                            <Table.Summary.Row>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell>Impuestos 19%</Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    <Text>{`${-totalamount * 0.19} COP`}</Text>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                            <Table.Summary.Row>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell>Total</Table.Summary.Cell>
                                <Table.Summary.Cell colSpan={2}>
                                    <Text>{`${-totalamount} COP`}</Text>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                        );
                    }}
                    />
                    <p>Las sumas de la liquidación se muestra el movimiento real de los recursos e insumos usados en la obra.</p>
                    <br />
                    <br />
                    <p>Autorizado por:</p>
                    <br />
                    <p>________________________</p>
                    <p>{`${user.firstName} ${user.lastName}`}</p>
                    <p>{`${user.title}, ${user.compnayName}`}</p>
            </PrintOrganism>
        </div>
    )
};

// const data = [
//     {
//       key: '1',
//       ref: '2543',
//       name: 'John Brown',
//       amount: 2,
//       price: 100000,
//     },
//     {
//       key: '2',
//       ref: '2544',
//       name: 'Jim Green',
//       amount: 1,
//       price: 100000,
//     }
//   ];

  const columns = [
    {
      title: 'Referencia',
      dataIndex: 'ref',
    },
    {
      title: 'Producto',
      dataIndex: 'num',
    },
    {
      title: 'Cantidad',
      dataIndex: 'total',
      render: (text, row) => {console.log(row); return (<p>{row.type === 'Entrada' ? -text : row.type === "Salida" ? text : row.type === "completed" ? text : 0}</p>)},
    },
    {
      title: 'Precio/Unitario',
      dataIndex: 'price'
    }
  ];