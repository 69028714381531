import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Formik, FieldArray } from 'formik';
import * as yup from 'yup';
import { MainForm } from './style';
import { ApiService } from '../../../services'
import { PageTitle } from '../../molecules/page-title';
import { FormikInput } from '../../molecules/formik-input-field';
import { AtomicButton } from '../../atoms/button';
import { getUserData } from '../../../store/auth/auth.selectors'

export const PurchaseCreatePage = () => {
    const history = useHistory();
    const { id } = useSelector(getUserData);
    
    const createPurchase = async (body) => {
        const { data } = await ApiService.PurchaseService.createPurchase(body);
        console.log(data);
        if(data.code === 200) history.push(`/purchase/${data.data.newPurchase.id}`)
    };
    return (
        <div>
            <div style={{ width: '100px' }}>
                <AtomicButton onClick={() => history.goBack()}>Atrás</AtomicButton>
            </div>
            <br />
            <PageTitle title={`Consecutivo de compras:  `} />
            <Formik
                initialValues={{
                    company: '',
                    status: 'Inicio de compra',
                    type: '',
                    value: 0,
                    obs:'',
                    progress: [
                        {
                            updatedBy: id,
                            title: 'Inicio de compra',
                            status: 'Terminado',
                            description: 'Inicio de compra'
                        }
                    ]
                }}
                onSubmit={(data) => {
                    createPurchase(data); // 
                }}
                validationSchema={yup.object().shape({
                    company: yup.string().required('Campo obligatorio'),
                    type: yup.string().required('Campo obligatorio'),
                    value: yup.number().required('Campo obligatorio'),
                    items: yup.array()
                    .of(
                      yup.object().shape({
                        ref: yup.string().required('Campo obligatorio'),
                        name: yup.string().required('Campo obligatorio'),
                        amount: yup.number().required('Campo obligatorio'),
                        price: yup.number().required('Campo obligatorio'),
                      })
                    )
                })}
                >
                {({ handleSubmit, values }) => (
                    <MainForm onSubmit={handleSubmit} noValidate>
                        <FormikInput type="text" label="Proveedor" name="company" />
                        <FormikInput type="text" label="Centro de costo" name="type" />
                        <FormikInput type="text" label="Valor" name="value" />
                        <FormikInput type="text" label="Observaciones" name="obs" />
                        <FieldArray
                            name="items"
                            render={arrayHelpers => (
                            <>
                                {values.items && values.items.length > 0 ? (
                                values.items.map((friend, index) => (
                                    <div style={{ width: '100%' }} key={index}>
                                    <label>{`Item: ${index + 1}`}</label>
                                    <FormikInput type="text" name={`items.${index}.ref`} label="Referencia" />
                                    <FormikInput type="text" name={`items.${index}.name`} label="Nombre" />
                                    <FormikInput type="number" name={`items.${index}.amount`} label="Cantidad" />
                                    <FormikInput type="number" name={`items.${index}.price`} label="Valor unitario" />
                                    <div style={{ display: 'flex'}}>
                                        <AtomicButton
                                            type="button"
                                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                        >
                                            Quitar Item
                                        </AtomicButton>
                                        <AtomicButton
                                            type="button"
                                            onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                        >
                                            Nuevo Item
                                        </AtomicButton>
                                    </div>
                                    </div>
                                ))
                                ) : (
                                <AtomicButton type="button" onClick={() => arrayHelpers.push('')}>
                                    {/* show this when user has removed all friends from the list */}
                                    Agregar Item
                                </AtomicButton>
                                )}
                            </>
                            )}
                        />
                        <div>
                            <AtomicButton onClick={handleSubmit}>Crear compra</AtomicButton>
                        </div>
                    </MainForm>
                )}
            </Formik>
        </div>
    )
};
