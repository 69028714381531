import { Layout } from 'antd';
import styled from 'styled-components';
import { branding } from '../../../constants/info'

const fondos = [
  'https://amebyana.com/images/fondo.png',
  'https://amebyana.com/images/fondo.png',
  'https://amebyana.com/images/fondo.png',
  'https://amebyana.com/images/fondo.png',
  'https://amebyana.com/images/fondo.png',
  'https://amebyana.com/images/fondo.png',
  'https://amebyana.com/images/fondo.png'
];
export const StyledBody = styled(Layout.Content)`
  overflow-y: auto;
  background-color: #fff;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  overflow: scroll;
  background: url('${fondos[Math.floor(Math.random() * fondos.length)]}');
  margin-bottom: 1em;
  background-position: center;
  background-size: cover;
  & > div:first-child {
    padding-top: calc(4 * 8px);
    width: 100%;
  }
`;

export const StyledBody2 = styled(Layout.Content)`
  overflow-y: auto;
  background-color: #fff;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 2rem;
  height: 100vh;
  overflow: scroll;
  background: rgba(250, 250, 250, 0.8);
  margin-bottom: 1em;
  background-position: center;
  background-size: cover;
  & > div:first-child {
    padding-top: calc(4 * 8px);
    width: 100%;
  }
`;

export const StyledAside = styled(Layout.Sider)`
  background: #fff;
  position: 'fixed';
  left: 0;
  height: 100vh;
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
  .header-side {
    height: 10vh;
    margin: 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
      'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: 600;
    font-style: italic;
  }
  & > .ant-layout-sider-trigger {
    background: #cacaca;
    color: black;
  }
  & > .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border: none;
  }
  @media (max-width: 700px) {
    display: none;
  }
`;

export const StyledNav = styled.nav`
  background-color: white;
  width: 100%;
  position: fixed;
  padding: 5px;
  z-index: 100;
  @media (min-width: 700px) {
    display: none;
  }
`;

export const StyledFooter = styled(Layout.Footer)`
  background-color: ${branding.primaryColor} !important;
  padding: 0;
  margin: auto 13px 10px;
  background: linear-gradient( 
      310deg,
      ${branding.primaryColor},
      ${branding.primaryBackgroundColor}
  );
    color: rgb(255, 255, 255);
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.5;
    text-transform: uppercase;
    letter-spacing: 0.02857em;
    min-width: 64px;
    border-radius: 4px;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #fff;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    width: 100%;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 0.75rem;
    font-weight: 700;
    border-radius: 0.5rem;
    line-height: 1.4;
    text-align: center;
    text-transform: uppercase;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-background-size: 150%!important;
    background-size: 150%!important;
    background-position-x: 25%!important;
    -webkit-transition: all 150ms ease-in;
    transition: all 150ms ease-in;
    min-height: 2.5rem;
    color: #67748e;
    box-shadow: 0rem 0.25rem 0.4375rem -0.0625rem rgb(0 0 0 / 11%), 0rem 0.125rem 0.25rem -0.0625rem rgb(0 0 0 / 7%);
    & > ul {
      width: 100%;
      height: auto;
    }
    .ant-menu {
      background: transparent;
    }
    .ant-menu-vertical {
      margin-top: 0;
      margin-bottom: 0;
    }
    .ant-menu-item{
      color: rgb(255, 255, 255);
      display: -webkit-inline-box;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      position: relative;
      box-sizing: border-box;
      -webkit-tap-highlight-color: transparent;
      background-color: transparent;
      outline: 0;
      border: 0;
      border-radius: 0;
      padding: 0;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      vertical-align: middle;
      -moz-appearance: none;
      -webkit-appearance: none;
      -webkit-text-decoration: none;
      text-decoration: none;
      color: inherit;
      font-family: "Roboto","Helvetica","Arial",sans-serif;
      font-size: 0.875rem;
      font-weight: 700;
      line-height: 1.5;
      text-transform: uppercase;
      letter-spacing: 0.02857em;
      min-width: 64px;
      border-radius: 4px;
      -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      color: #fff;
      background-color: #cb0c9f;
      box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
      width: 100%;
      display: -webkit-inline-box;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      font-size: 0.75rem;
      font-weight: 700;
      border-radius: 0.5rem;
      line-height: 1.4;
      text-align: center;
      text-transform: uppercase;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-background-size: 150%!important;
      background-size: 150%!important;
      background-position-x: 25%!important;
      -webkit-transition: all 150ms ease-in;
      transition: all 150ms ease-in;
      background-color: #ffffff;
      min-height: 2.5rem;
      color: #fff;
      box-shadow: 0rem 0.25rem 0.4375rem -0.0625rem rgb(0 0 0 / 11%), 0rem 0.125rem 0.25rem -0.0625rem rgb(0 0 0 / 7%);
      background-color: ${branding.primaryColor};
    }
}
`;

export const MainContent = styled.div`
  background-color: white;
`;

export const StyledMainUser = styled.div`
  margin: 0 auto;
  height: 15vh;
  font-size: 1rem;
  line-height: 1.625;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  color: #344767;
  font-weight: 500;
  letter-spacing: 0.0075em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  & > p {
    margin: 0;
  }
`;

export const StyledHr = styled.hr`
  margin: 10px 0px;
  background-color: #67748E;
  opacity: 0.1;
`;