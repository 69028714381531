import React from 'react';
import { StyledSpiner } from './style';
import { frases } from '../../../constants/info';

export const LoaderAtom = () => {
  return (
    <StyledSpiner tip={frases[Math.floor(Math.random() * frases.length)]} />
  );
};

