export class ShipmentsService {
  constructor(client) {
    this.client = client;
  }

  myShipments(){
    return this.client.get('/carriers/dhl/get-orders');
  }

  trackShipment(values){
    return this.client.post('/carriers/dhl/know-tracking', values);
  }

  bookPickUpShipment(values){
    return this.client.post('/carriers/dhl/book-pickup', values);
  }
}
