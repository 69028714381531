export class AuthService {
  constructor(client) {
    this.client = client;
  }

  getUsers() {
    return this.client.get('/auth/get-user');
  }

  getAllUsers(){
    return this.client.get('/users/getAllUsers');
  }

  me() {
    return this.client.get('/auth/me');
  }
  
  changeStatus(id) {
    return this.client.get(`/auth/change-status/${id}`);
  }

  login(values) {
    console.log(values);
    return this.client.post('/auth/login', values);
  }
}
