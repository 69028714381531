import React from 'react';
import { ProjectModule } from '../../organims/project-archive';
import { PageTitle } from '../../molecules/page-title';

export const ProjectsPage = () => {

  return (
    <div>
      <PageTitle  title="Proyectos" />
      <p>En el módulo de usuarios podremos administrar y analizar los usuarios de la plataforma...</p>
      <ProjectModule />
    </div>
  );
};

