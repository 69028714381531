import React, { useState } from 'react';
import { ApiService } from '../../../services';
import { TableCustom } from '../../organims/table';
import { useHistory, useParams } from 'react-router-dom';
import { PageTitle } from '../../molecules/page-title';
import { AtomicButton } from '../../atoms/button';
import { LoaderAtom } from '../../atoms/loading';
import { ProjectEndorganism } from '../../organims/project-final';
import { Tabs } from 'antd';
import { useEffect } from 'react';

const { TabPane } = Tabs;

export const ProductDetailPage = () => {
    const { id } = useParams();
    const history = useHistory();
    const [productOrders , setProductOrders] = useState(null);
    const [product , setProduct] = useState(null);
    const [productVars, setProductVars] = useState(null);

    function callback(key) {
        console.log(key);
    };
    const columnsVars = [
        {
            title: '# de Var',
            dataIndex: 'id',
            key: 'id',
            width: '100px'
        },
        {
            title: 'Atributo',
            dataIndex: 'id',
            key: 'id',
            width: '100px',
            render: (text, variant) => (
                <p>{`${variant.attributes[0].name}-${variant.attributes[0].option}`}</p>
              ) 
        }
    ]
    const columns = [
        {
          title: '# de Orden',
          dataIndex: 'id',
          key: 'id',
          width: '100px'
        },
        {
          title: 'Estado',
          dataIndex: 'status',
          key: 'id',
          width: '100px'
        },
        {
          title: 'Productos',
          dataIndex: 'total',
          key: 'id',
          width: '100px',
          render: (text, order) => (
            <div>
                {order.line_items.map((item, index) => (
                    <p key={item.id}>{`${index + 1}. ${item.name}`}</p>
                ))}
            </div>
          ) 
        },
        {
          title: 'Info de Envío',
          dataIndex: 'total',
          key: 'id',
          width: '100px',
          render: (text, order) => (
            <div>
                <p>{`Envío: ${order.shipping.address_1}, ${order.shipping.city}, ${order.shipping.country}`}</p>
                <p>{`Contacto: ${order.shipping.first_name} ${order.shipping.last_name}`}</p>
            </div>
          ) 
        },
        {
          title: 'Info de Factura',
          dataIndex: 'total',
          key: 'id',
          width: '100px',
          render: (text, order) => (
            <div>
                <p>{`Envío: ${order.billing.address_1}, ${order.billing.city}, ${order.billing.country}`}</p>
                <p>{`Contacto: ${order.billing.first_name}, ${order.billing.last_name}. Correo: ${order.billing.email || 'N/A'}. Cel: ${order.billing.phone || 'N/A'}`}</p>
            </div>
          ) 
        },
        {
          title: 'Valor',
          dataIndex: 'total',
          key: 'id',
          width: '100px',
          render: (text, order) => (
            <div>
                <p>{`Envío: ${order.shipping_total} COP`}</p>
                <p>{`Iva: ${parseFloat(order.total_tax) === 0 ? 'Incluido' : order.total_tax} COP`}</p>
                <p>{`Total: ${order.total} COP`}</p>
            </div>
          ) 
        }
      ];
    const fetchProductOrders = async () => {
        try {
            const productOrders = await ApiService.WooService.getOrdersByProductId(id);
            const product = await ApiService.WooService.getProductById(id);
            const productVars = await ApiService.WooService.getProductVariations(id);
            console.log("🚀 ~ file: index.js ~ line 36 ~ fetchProductOrders ~ productOrders", productVars.data);
            setProduct(product.data)
            setProductOrders(productOrders.data)
            setProductVars(productVars.data);
        } catch (error) {
            console.log("🚀 ~ file: index.js ~ line 38 ~ fetchProductOrders ~ error", error)  
        }
    };
    useEffect(() => {
        fetchProductOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    if( !productVars ||  !product || !productOrders) return <LoaderAtom />;
    const movementSummry = productOrders.map(pro => {
        const n = pro.line_items.map(s => ({...s, ref: s.id, num:s.name, type: pro.status, total: s.quantity, price: s.price }))
        return n;
    });
    return (
        <div>
            <div style={{ width: '100px' }}>
                <AtomicButton onClick={() => history.goBack()}>Atrás</AtomicButton>
            </div>
            <br />
            <PageTitle title={`Producto: ${product.name} `} />
            <p>{`Fecha creación: ${new Date(product.date_created).toLocaleDateString()}`}</p>
            <p>{`Fecha última modificación: ${new Date(product.date_modified).toLocaleDateString()}`}</p>
            <p>Descripción:</p>
            <div className="content" dangerouslySetInnerHTML={{__html: product.description}}></div>
            <p>{`Stock: ${product.stock_quantity || 'N/A'}`}</p>
            <p>{`Ventas totales: ${product.total_sales || '0'}`}</p>
            <p>{`Precio: ${product.price} COP`}</p>
            <img src={product.images[0].src} alt="Imagen producto" width="200px" />
            <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="Variaciones de producto" key="1">
                    <PageTitle title="Variaciones de producto" />
                    <TableCustom columns={columnsVars} data={productVars} />
                </TabPane>
                <TabPane tab="Ordenes de Producto" key="2">    
                    <PageTitle title="Ordenes de Producto" />
                    <TableCustom columns={columns} data={productOrders} />
                </TabPane>
                <TabPane tab="Imprimir Resumen de Producto" key="3">
                    <PageTitle title="Resumen de proyecto" />
                    <ProjectEndorganism items={movementSummry.flat()} />
                </TabPane>
            </Tabs>
        </div>
    )
};