import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { ApiService } from '../../../services'
// import { Tag, Space, Button  } from 'antd';
import { TableCustom } from '../../organims/table';
import { useHistory } from 'react-router-dom';
import { ModalBalance } from '../../organims/modal-currency';
import { PageTitle } from '../../molecules/page-title';
import { LoaderAtom } from '../../atoms/loading';
import { AtomicButton } from '../../atoms/button';

export const ProductsPage = () => {
  const [awsEndpoint, setAwsEndpoint] = useState(false);
  const [products, setProducts] = useState(null);
  const balanceRef = useRef(null);
  const history = useHistory();
  const getPurchases = async () => {
    if(awsEndpoint){
      setProducts(null);
      const { data } = await ApiService.WooService.getAWSProducts()
      console.log(data);
      setProducts(data);
    } else {
      setProducts(null);
      const { data } = await ApiService.WooService.getProducts();
      console.log(data);
      setProducts(data);
    }
  };
  useEffect(() => getPurchases(), [awsEndpoint])

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      width: '100px'
    },
    {
      title: 'Precio',
      dataIndex: 'price',
      key: 'id',
      width: '100px'
    },
    {
      title: 'Ventas',
      dataIndex: 'total_sales',
      key: 'id',
      width: '100px'
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <AtomicButton onClick={() => history.push(`/productos/${record.id}`)}>+ Info</AtomicButton>
      ),
      width: '100px'
    }
  ];

  return (
    <div>
      <PageTitle  title="Productos" />
      <p>En el módulo de productos es para monitorear los productos</p>
      <AtomicButton onClick={() => setAwsEndpoint(!awsEndpoint)}>Cambiar servidor</AtomicButton>
      {products ? (
        <>
          <TableCustom columns={columns} data={products} />
          <ModalBalance ref={balanceRef} />
        </>
      ) : (
        <LoaderAtom />
      )}
    </div>
  );
};

