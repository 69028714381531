import React from 'react';
import { useHistory/* , useParams */ } from 'react-router-dom';
import { Table, Typography } from 'antd';
import { useSelector } from 'react-redux';
// import { Formik } from 'formik';
// import { MainForm } from './style';
// import { ApiService } from '../../../services'
import { PageTitle } from '../../molecules/page-title';
// import { FormikInput } from '../../molecules/formik-input-field';
import { AtomicButton } from '../../atoms/button';
import { getUserData } from '../../../store/auth/auth.selectors'
import { PrintOrganism } from '../../organims/print-organism';
const { Text } = Typography;

export const PurchaseOCPage = (props) => {
    const history = useHistory();
    const user = useSelector(getUserData);
    if (!props.location.state) {
        history.go('/')
    }
    return (
        <div>
            <div style={{ width: '100px' }}>
                <AtomicButton onClick={() => history.goBack()}>Atrás</AtomicButton>
            </div>
            <br />
            <PageTitle title={`Generar O.C.:`} />
            <PrintOrganism>
                <br />
                <h4>Orden de compra</h4>
                <h4>{`Consecutivo de compra: ${props.location.state.purchase_consec}`}</h4>
                <div style={{ display: 'flex', flexDirection: 'row'}}>
                    <div style={{ width: '50%' }}>
                        <p>Bylin S.A.S.</p>
                        <p>Nit: 800.130.961-4</p>
                        <p>Correo: auxlogistica@bylinsas.com</p>
                        <p>Tel: 745 0338 +103</p>
                    </div>
                    <div style={{ width: '50%' }}>
                        <p>{`Proveedor: ${props.location.state.company} `}</p>
                        <p>{`Nit proveedor: ${props.location.state.nit || 'N/A'} `}</p>
                        <p>{`Correo proveedor: ${props.location.state.email || 'N/A'} `}</p>
                        <p>{`Tel proveedor: ${props.location.state.tel || 'N/A'} `}</p>
                    </div>

                </div>
                <Table
                    columns={columns}
                    dataSource={props.location.state.items}
                    pagination={false}
                    // bordered
                    summary={pageData => {
                        let totalamount = 0;

                        pageData.forEach(({ amount, price }) => {
                        totalamount = (amount * price) + totalamount;
                        });

                        return (
                        <>
                            <Table.Summary.Row>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell>Impuestos 19%</Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    <Text>{totalamount * 0.19}</Text>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                            <Table.Summary.Row>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell>Total</Table.Summary.Cell>
                                <Table.Summary.Cell colSpan={2}>
                                    <Text>{totalamount * 1.19}</Text>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                        );
                    }}
                    />
                    <p>Si no hay términos diferentes acordados, se pagarán las facturas a los 30 días de que se expida la factura. a facturacion@bylinsas.com.</p>
                    <br />
                    <br />
                    <p>Autorizado por:</p>
                    <br />
                    <p>________________________</p>
                    <p>{`${user.firstName} ${user.lastName}`}</p>
                    <p>{`${user.title}, ${user.compnayName}`}</p>
            </PrintOrganism>
        </div>
    )
};

// const data = [
//     {
//       key: '1',
//       ref: '2543',
//       name: 'John Brown',
//       amount: 2,
//       price: 100000,
//     },
//     {
//       key: '2',
//       ref: '2544',
//       name: 'Jim Green',
//       amount: 1,
//       price: 100000,
//     }
//   ];

  const columns = [
    {
      title: 'Referencia',
      dataIndex: 'ref',
    },
    {
      title: 'Producto',
      dataIndex: 'name',
    },
    {
      title: 'Cantidad',
      dataIndex: 'amount',
    },
    {
      title: 'Precio',
      dataIndex: 'price',
    },
  ];