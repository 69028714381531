export class WalletService {
  constructor(client) {
    this.client = client;
  }

  getWalletAllUser() {
    return this.client.get('/wallet');
  }
  getWalletByUser(id) {
    return this.client.get('/wallet/' + id);
  }
  chargedBalance(id, values) {
    return this.client.put(`/wallet/add-balance/${id}`, values);
  }
}
