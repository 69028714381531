import React from 'react';
import { useHistory } from 'react-router-dom';
import { TableCustom } from '../../organims/table';
import { WrapperShipments, MainCardIndicator } from './styled';
import { PageTitle } from '../../molecules/page-title';
import { Tag, Space, Button, Row, Col } from 'antd';
import { projectList } from '../../../constants/projectData';
import GeneralHomeFunctions from '../../organims/general-home-functions';
import { funcionalidades } from '../../../constants/funcionalidades';

export const Tecnico = () => {
  const history = useHistory();

  const columns = [
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company'
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      render: (text, record) => (
        <Tag color='blue'>
          {record.status.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button onClick={() => history.push('/wrap-details')}>Ver detalles</Button>
        </Space>
      ),
    },
  ];
  return (
    <WrapperShipments>
      <PageTitle  title="Técnico" />
      <Row gutter={16}>
      {indicators.map(indicator => (
        <Col flex="1 0 25%">
          <MainCardIndicator
            title={indicator.title}
            bordered={false}
          >
            <h1>{indicator.content}</h1>
          </MainCardIndicator>
      </Col>
      ))}
      </Row>
      <p>Aquí se pueden ver estadísticas y metricas del departamento de Logística en Bylin S.A.S.</p>
      <GeneralHomeFunctions page="tecnico" funcionalidades={funcionalidades} />
      <h1>Obras (Reservas - Finalizados)</h1>
      <TableCustom
        columns={columns} 
        data={projectList} 
        searchableTable={true}
        searchablePlaceholder="Obra"  
        serachableObjKey='name'
      />
      <Row>
        <h1>Envíos:</h1>{' '}
      </Row>
      <TableCustom
        columns={columns} 
        data={projectList} 
        searchableTable={true}
        searchablePlaceholder="Obra"  
        serachableObjKey='name'
      />
      <Row>
        <h1>Programación de obras:</h1>{' '}
        <Button onClick={() => alert('Crear programación')}>Crear programación</Button>
      </Row>
      <TableCustom
        columns={columns} 
        data={projectList} 
        searchableTable={true}
        searchablePlaceholder="Obra"  
        serachableObjKey='name'
      />
      <Row>
        <h1>Programación de supervisores & instaladores:</h1>{' '}
        <Button onClick={() => alert('Crear programación')}>Crear programación</Button>
      </Row>
      <TableCustom
        columns={columns} 
        data={projectList} 
        searchableTable={true}
        searchablePlaceholder="Obra"  
        serachableObjKey='name'
      />
      <Row>
        <h1>Entradas:</h1>
      </Row>
      <TableCustom
        columns={columns} 
        data={projectList} 
        searchableTable={true}
        searchablePlaceholder="Obra"  
        serachableObjKey='name'
      />
      <Row>
        <h1>Salidas:</h1>{' '}
      </Row>
      <TableCustom
        columns={columns} 
        data={projectList} 
        searchableTable={true}
        searchablePlaceholder="Obra"  
        serachableObjKey='name'
      />
    </WrapperShipments>
  );
};

const indicators = [
  {
    title: 'Solicitudes',
    content: '150/Mes'
  },
  {
    title: 'Pendientes',
    content: '45/150'
  },
  {
    title: 'Finalizados',
    content: '33/150'
  }
];



