import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as yup from 'yup';
import { MainForm } from './style';
import { ApiService } from '../../../services'
import { PageTitle } from '../../molecules/page-title';
import { FormikInput } from '../../molecules/formik-input-field';
import { AtomicButton } from '../../atoms/button';
import { getUserData } from '../../../store/auth/auth.selectors'

export const TransportCreatePage = () => {
    const history = useHistory();
    const { id } = useSelector(getUserData);
    
    const createPurchase = async (body) => {
        const { data } = await ApiService.TransportService.createTransport(body);
        console.log(data);
        if(data.code === 200) history.push(`/transport/${data.data.newTransport.id}`)
    };
    return (
        <div>
            <div style={{ width: '100px' }}>
                <AtomicButton onClick={() => history.goBack()}>Atrás</AtomicButton>
            </div>
            <br />
            <PageTitle title={`Consecutivo de transporte:  `} />
            <Formik
                initialValues={{
                    movement_consec: '',
                    company: '',
                    status: 'Inicio de transporte',
                    type: '',
                    itr: false,
                    return2port: false,
                    load: false,
                    unload: false,
                    origin: '',
                    destination: '',
                    weight: 0,
                    value: 0,
                    obs:'',
                    progress: [
                        {
                            updatedBy: id,
                            title: 'Inicio de Transporte',
                            status: 'Terminado',
                            description: 'Inicio de transporte'
                        }
                    ]
                }}
                onSubmit={(data) => {
                    createPurchase(data); // 
                }}
                validationSchema={yup.object().shape({
                    movement_consec: yup.string().required('Campo obligatorio'),
                    company: yup.string().required('Campo obligatorio'),
                    type: yup.string().required('Campo obligatorio'),
                    value: yup.number().required('Campo obligatorio'),
                    itr: yup.boolean().required('Campo obligatorio'),
                    return2port: yup.boolean().required('Campo obligatorio'),
                    load: yup.boolean().required('Campo obligatorio'),
                    unload: yup.boolean().required('Campo obligatorio'),
                    origin: yup.string().required('Campo obligatorio'),
                    destination: yup.string().required('Campo obligatorio'),
                    weight: yup.number().required('Campo obligatorio'),
                })}
                >
                {({ handleSubmit, values }) => (
                    <MainForm onSubmit={handleSubmit} noValidate>
                        <FormikInput type="text" label="Consecutivo" name="movement_consec" />
                        <FormikInput type="text" label="Transportadora" name="company" />
                        <FormikInput type="text" label="Centro de costo" name="type" />
                        <FormikInput type="number" label="Valor" name="value" />
                        <FormikInput type="checkbox" label="Itr" name="itr" />
                        <FormikInput type="checkbox" label="Retorno a origen" name="return2port" />
                        <FormikInput type="checkbox" label="Cargue" name="load" />
                        <FormikInput type="checkbox" label="Descargue" name="unload" />
                        <FormikInput type="text" label="Origen" name="origin" />
                        <FormikInput type="text" label="Destino" name="destination" />
                        <FormikInput type="number" label="Weight" name="weight" />
                        <div>
                            <AtomicButton onClick={handleSubmit}>Crear Transporte</AtomicButton>
                        </div>
                    </MainForm>
                )}
            </Formik>
        </div>
    )
};
