import { AuthService } from './auth.service';
import { ConfigService } from './config.service';
import { WalletService } from './wallet.service';
import { PricingService } from './pricing.service';
import { ShipmentsService } from './shipments.service';
import { PurchaseService } from './purchase.service';
import { TransportService } from './transport.service';
import { StockService } from './stock.service';
import { MarketingService } from './marketing.service';
import { WooService } from './woo.service';
import { clients } from '../utils/interceptors'
class _ApiService {
  client = clients.default;
  ameClient = clients.marketing;
  ameWoo = clients.woo;
  ameWooAws = clients.wooAWS
  AuthService = new AuthService(this.ameClient);
  PurchaseService = new PurchaseService(this.client);
  TransportService = new TransportService(this.client);
  StockService = new StockService(this.client);
  WalletService = new WalletService(this.client);
  ConfigService = new ConfigService(this.client);
  PricingService = new PricingService(this.client);
  ShipmentsService = new ShipmentsService(this.client);
  MarketingService = new MarketingService(this.marketing);
  WooService = new WooService(this.ameWoo, this.ameWooAws);
  constructor(common, ameClient, ameWoo, ameWooAws) {
    this.client.defaults.baseURL = common;
    this.ameClient.defaults.baseURL = ameClient;
    this.ameWoo.defaults.baseURL = ameWoo;
    this.ameWooAws.defaults.baseURL = ameWooAws;
  }
};

/* export const ApiService = new _ApiService('http://localhost:3000/dev'); */
export const ApiService = new _ApiService(
   'https://bylinprod.herokuapp.com/v1', 'https://abats7z7dl.execute-api.us-east-1.amazonaws.com/dev', 'https://celebritiesstore.com', 'http://54.167.229.41' //  'http://localhost:3000/dev' //'http://localhost:3002/v1' //
);

