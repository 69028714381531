import React from 'react';
import { useHistory } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
import { TableCustom } from '../../organims/table';
import { WrapperShipments, MainCardIndicator } from './styled';
import { PageTitle } from '../../molecules/page-title';
import { branding } from '../../../constants/info';
// import { ApiService } from '../../../services';
import { Tag, Space, Button, Row, Col } from 'antd';
import { projectList } from '../../../constants/projectData';
import GeneralHomeFunctions from '../../organims/general-home-functions';
import { funcionalidades } from '../../../constants/funcionalidades';

export const Logistics = () => {
  const history = useHistory();
  // const dispatch = useDispatch();
  // const [ envios, setEnvios ] = useState()
  // useEffect(() => {
  //   dispatch({
  //     type: 'test',
  //     success: {
  //       id: 'custom',
  //     },
  //   });
  //   test()
  // }, [dispatch]);
  // const test = async () => {
  //   try {
  //     const { data } = await ApiService.ShipmentsService.myShipments();
  //     console.log(data);
  //     setEnvios(data.data.docs.map(ship => ({...ship, key: ship.id, transportadores: 'dhl'})));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const columns = [
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company'
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      render: (text, record) => (
        <Tag color='blue'>
          {record.status.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button onClick={() => history.push('/wrap-details')}>Ver detalles</Button>
        </Space>
      ),
    },
  ];
  return (
    <WrapperShipments>
      <PageTitle  title="Logistica" />
      <Row gutter={16}>
      {indicators.map(indicator => (
        <Col flex="1 0 25%">
          <MainCardIndicator
            title={indicator.title}
            bordered={false}
          >
            <h1>{indicator.content}</h1>
          </MainCardIndicator>
      </Col>
      ))}
      </Row>
      <p>Aquí se pueden ver estadísticas y metricas del departamento de Logística en {branding.brandName}</p>
      <GeneralHomeFunctions page="logistica" funcionalidades={funcionalidades} />
      <h1>Obras (Reservas - Recolección)</h1>
      <TableCustom
        columns={columns} 
        data={projectList} 
        searchableTable={true}
        searchablePlaceholder="Obra"  
        serachableObjKey='name'
      />
      <Row>
        <h1>Envíos:</h1>{' '}
        <Button onClick={() => alert('Crear una ruta')}>Registrar cotización de envíos.</Button>
      </Row>
      <TableCustom
        columns={columns} 
        data={projectList} 
        searchableTable={true}
        searchablePlaceholder="Obra"  
        serachableObjKey='name'
      />
      <Row>
        <h1>Ruta:</h1>{' '}
        <Button onClick={() => alert('Crear una ruta')}>Crear ruta</Button>
      </Row>
      <TableCustom
        columns={columns} 
        data={projectList} 
        searchableTable={true}
        searchablePlaceholder="Obra"  
        serachableObjKey='name'
      />
      <Row>
        <h1>Entradas:</h1>{' '}
        <Button onClick={() => alert('Crear una entrada al inventario')}>Crear entrada nueva</Button>
      </Row>
      <TableCustom
        columns={columns} 
        data={projectList} 
        searchableTable={true}
        searchablePlaceholder="Obra"  
        serachableObjKey='name'
      />
      <Row>
        <h1>Salidas:</h1>{' '}
        <Button onClick={() => alert('Crear una salida del inventario')}>Crear salida nueva</Button>
      </Row>
      <TableCustom
        columns={columns} 
        data={projectList} 
        searchableTable={true}
        searchablePlaceholder="Obra"  
        serachableObjKey='name'
      />
    </WrapperShipments>
  );
};

const indicators = [
  {
    title: 'Despachos',
    content: '150/Mes'
  },
  {
    title: 'Pendientes',
    content: '45/150'
  },
  {
    title: 'Finalizados',
    content: '33/150'
  }
];



