import React, { useEffect, useState } from 'react'
import { ApiService } from '../../../services';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Transition } from '../general-transition';
import { PageTitle } from '../../molecules/page-title';
import { StockInnerTableOrganism } from '../../organims/stock-inner-table-organism';
import { AtomicButton } from '../../atoms/button';
import { LoaderAtom } from '../../atoms/loading';

export const InventoryModule = () => {
  const [items, setItems] = useState(null);
    const { t } = useTranslation();
    const history = useHistory();
    const getPurchases = async () => {
      const { data } = await ApiService.StockService.getItems();
      const sorted = data.data.items.sort((a,b) => (a.num > b.num) ? 1 : ((b.num > a.num) ? -1 : 0))
      setItems(sorted);
    };
    useEffect(() => getPurchases(), [])
    if(!items) return <LoaderAtom />;
    return (
        <Transition>
          <PageTitle title={t('Inventario')} />
          <AtomicButton width="auto" onClick={() => history.push('/stock-create-ref')}>Crear referencia</AtomicButton>
          <AtomicButton width="auto" onClick={() => history.push(`/item-search`)}>Busuqeda de inventario</AtomicButton>
          <AtomicButton width="auto" onClick={() => history.push(`/movement-create/Entrada`)}>Generar Entrada</AtomicButton>
          <AtomicButton width="auto" onClick={() => history.push(`/movement-create/Reserva`)}>Solicitar reserva</AtomicButton>
          <AtomicButton width="auto" onClick={() => alert('Crear referencia')}>Proforma de salida</AtomicButton>
          <AtomicButton width="auto" onClick={() => alert('Crear referencia')}>Aplicar salida</AtomicButton>
          <StockInnerTableOrganism stock={items} />
        </Transition>
    )
};