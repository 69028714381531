import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserData } from '../../../store/auth/auth.selectors';
import { ApiService } from '../../../services';
import { StyledRefMainRowInnerImg } from './style';
import { useHistory, useParams } from 'react-router-dom';
import { PageTitle } from '../../molecules/page-title';
import { AtomicButton } from '../../atoms/button';
import { LoaderAtom } from '../../atoms/loading';
import { StockInnerTableOrganism } from '../../organims/stock-inner-table-organism';
import { MovementTableOrganism } from '../../organims/movement-table-organism';

export const StockRefDetailPage = () => {
    const params = useParams();
    const history = useHistory();
    const { gerencia } = useSelector(getUserData);
    const [ref, setRef] = useState(null);
    const [mov, setMov] = useState(null);
    const getInfo = async () => {
        const { data } = await ApiService.StockService.getStockById(params.id);
        // console.log(data);
        setRef(data.data.stock);
    };
    const getInfo2 = async () => {
        const { data } = await ApiService.StockService.getMovements();
        // console.log(data);
        setMov(data.data.movements);
    };
      useEffect(() => {
          getInfo();
          getInfo2();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])
    if(!ref || !mov) return <LoaderAtom />;
    return (
        <div>
            <div style={{ width: '100px' }}>
                <AtomicButton onClick={() => history.goBack()}>Atrás</AtomicButton>
            </div>
            <br />
            <PageTitle title={`Referencia: ${ref.ref} ${ref.name} `} />
            {gerencia && 
            (<div>
                <AtomicButton onClick={() => history.push('/stock-edit-ref', ref)}>Editar</AtomicButton>
            </div>)}
            <p>{ref.name}</p>
            <StyledRefMainRowInnerImg img={ref.floorImg} />
            <p>{`${ref.tipo}-${ref.collecion}`}</p>
            <p>{`Total: ${ref.total}${ref.unidad}, Reservado: ${ref.reservado}${ref.unidad} Disponible: ${ref.disponible}${ref.unidad}`}</p>
            {ref.refSoldadura && (<p>{`Soldadura: ${ref.refSoldadura}`}</p>)}
            {ref.refSoldadura && (<p>{`Sold.Total: ${ref.soldaduraTotal}Ml, Reservado: ${ref.soldaduraReservada}Ml Disponible: ${ref.soldaduraDisponible}Ml`}</p>)}
            <h3>Observaciones:</h3>
            <p>{ref.obs || 'Sin observaciones...'}</p>
            <PageTitle title="Especificaciones" />
            <p>{`Unidad de medida: ${ref.unidad}`}</p>
            <p>{`Presentación: ${ref.presentacion}`}</p>
            <p>{`Rendimiento: ${ref.rendimiento} x 1${ref.unidad}`}</p>
            <p>{`Peso: ${ref.peso} x 1${ref.unidad}`}</p>
            <AtomicButton onClick={() => alert('Descarga de ficha técnica')}>Ficha Tecnica</AtomicButton>
            <PageTitle title="Stock:" />
            <p>{ref.stock.length === 0 ? 'Este producto no tiene stock' : 'Este producto si tiene stock'}</p>
            <StockInnerTableOrganism stock={ref.stock} />
            <PageTitle title="Movimientos:" />
            <p>{ref.movimientos.length === 0 ? 'Este producto no presenta movimientos' : 'Este producto tiene movimiento'}</p>
            <MovementTableOrganism inventory={[...new Map(ref.movimientos.map(item => [item.id, item])).values()]} />
        </div>
    )
};
