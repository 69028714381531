export class PricingService {
  constructor(client) {
    this.client = client;
  }

  getLastCurrency() {
    return this.client.get('/pricing/find-last-currency');
  };

  putNewCurrency(id) {
    return this.client.put(`/pricing/currency?currency=${id}`);
  };
}
