export const funcionalidades = [
    {
      label: "Default",
      componentName: null,
      iconName: 'CalendarOutlined',
      page: ['gerencia', 'ventas', 'logistica', 'tecnico', 'contabilidad' ]
    },
    {
      label: "Compras",
      componentName: "PurchaseModule",
      iconName: 'CalendarOutlined',
      page: ['gerencia', 'ventas', 'logistica', 'tecnico', 'contabilidad' ]
    },
    // {
    //   label: "Lista de prospectos",
    //   componentName: "EmailListModule",
    //   iconName: 'CalendarOutlined',
    //   page: []
    // },
    // {
    //   label: "Transportes",
    //   componentName: "TransportModule",
    //   iconName: 'CalendarOutlined',
    //   page: []
    // },
    // {
    //   label: "Inventario",
    //   componentName: "InventoryModule",
    //   iconName: 'CalendarOutlined',
    //   page: []
    // },
    // {
    //   label: "Obras",
    //   componentName: 'ProjectModule',
    //   iconName: 'CalendarOutlined',
    //   page: []
    // }
  ];

  export const funcionalidades1 = [
    {
      label: "Calendario",
      iconName: 'CalendarOutlined',
      page: ['gerencia', 'ventas', 'logistica', 'tecnico', 'contabilidad' ]
    },
    {
      label: "Ventas",
      iconName: 'NotificationOutlined',
      page: ['gerencia', 'ventas' ]
    },
    {
      label: "Cliente",
      iconName: 'NotificationOutlined',
      page: ['gerencia', 'ventas' ]
    },
    {
      label: "Obras",
      iconName: 'NotificationOutlined',
      page: ['gerencia', 'ventas', 'logistica', 'tecnico' ]
    },
    {
      label: "Reservas",
      iconName: 'NotificationOutlined',
      page: ['gerencia', 'ventas', 'logistica' ]
    },
    {
      label: "Salidas",
      iconName: 'NotificationOutlined',
      page: ['gerencia', 'ventas', 'logistica', 'tecnico' ]
    },
    {
      label: "Entradas",
      iconName: 'NotificationOutlined',
      page: ['gerencia', 'ventas', 'logistica', 'tecnico' ]
    },
    {
      label: "Facturas",
      iconName: 'NotificationOutlined',
      page: ['gerencia', 'ventas' ]
    },
    {
      label: "Lógistica",
      iconName: 'CarOutlined',
      page: ['gerencia', 'logistica' ]
    },
    {
      label: "Rutas",
      iconName: 'CarOutlined',
      page: ['gerencia', 'logistica' ]
    },
    {
      label: "Envíos",
      iconName: 'CarOutlined',
      page: ['gerencia', 'logistica', 'tecnico' ]
    },
    {
      label: "Tecnico",
      iconName: 'ToolOutlined',
      page: ['gerencia', 'tecnico'] 
    },
    {
      label: "Programación",
      iconName: 'ToolOutlined',
      page: ['gerencia', 'tecnico'] 
    },
    {
      label: "SISO",
      iconName: 'ToolOutlined',
      page: ['gerencia', 'tecnico'] 
    },
    {
      label: "Inventario",
      iconName: 'FileProtectOutlined',
      page: ['gerencia', 'ventas', 'logistica', 'tecnico', 'contabilidad' ] 
    },
    {
      label: "Contabilidad",
      iconName: 'WalletOutlined',
      page: ['gerencia', 'contabilidad' ] 
    },
    {
      label: "Gastos",
      iconName: 'WalletOutlined',
      page: ['gerencia', 'contabilidad' ] 
    },
    {
      label: "Ingresos",
      iconName: 'WalletOutlined',
      page: ['gerencia', 'contabilidad' ] 
    },
    {
      label: "Nómina",
      iconName: 'WalletOutlined',
      page: ['gerencia', 'contabilidad' ] 
    },
    {
      label: "Calendario Físcal",
      iconName: 'WalletOutlined',
      page: ['gerencia', 'contabilidad' ] 
    }
  ];