import React from 'react';
import * as Icons from '@ant-design/icons';
import { MainCardIndicatorStyled, StyledCardInner, StyledCardInnerInfo, StyledCardInnerSymbol, StyledCardInnerSymbolBox } from './style';

export const MainCardIndicator = ({ indicator }) => {
    const IconComponent = Icons[indicator.icon || 'UploadOutlined'];
    return (
        <MainCardIndicatorStyled>
            <StyledCardInner>    
                <StyledCardInnerInfo percent={indicator.acendente ? indicator.crecimiento : indicator.crecimiento * -1}>
                    <span>{indicator.title}</span>
                    <h1>
                        {indicator.content}
                        <span>{indicator.crecimiento}%</span>
                    </h1>
                </StyledCardInnerInfo>
                <StyledCardInnerSymbol>    
                    <StyledCardInnerSymbolBox>
                        <IconComponent />
                    </StyledCardInnerSymbolBox>
                </StyledCardInnerSymbol>
            </StyledCardInner>
        </MainCardIndicatorStyled>
    )
};

