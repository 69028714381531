export const getAllhistory = (values) => {
  const newValues = {};
  values.forEach((value) => {
    newValues[value._id] = value;
  });
  return newValues;
};

export const allHistoryTransform = (historyData) => {
  const history = historyData.map((history) => {
    return {
      key: history._id,
      _id: history._id,
      email: history.wallet?.user?.email,
      userId: history.wallet?.user?._id,
      walletId: history.wallet?._id,
      amount: history.amount,
      type: history.type,
      origin: history.origin,
      ownerName: history.owner?.email,
      ownerId: history.owner?._id,
      createdAt: history.created_at,
    };
  });
  return history;
};
