import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ApiService } from '../../../services'
import { Steps } from 'antd';
import { PageTitle } from '../../molecules/page-title';
import { AtomicButton } from '../../atoms/button';
import { Base64PreviewMolecule } from '../../molecules/pdf-previewer-molecule';
import { LoaderAtom } from '../../atoms/loading';
const { Step } = Steps;

export const PurchaseDetailPage = () => {
    const history = useHistory();
    const params = useParams();
    const [purchase, setPurchase] = useState();
    const [current, setCurrent] = useState(purchase?.progress.length);
    const handleChange = (index) => {
        setCurrent(index);
    };
    const getInfo = async () => {
        const { data } = await ApiService.PurchaseService.getPurchaseById(params.id);
        // console.log(data);
        setPurchase(data.data.purchase);
    };
    useEffect(() => {
        getInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (!purchase) return <LoaderAtom />;
    return (
        <div>
            <div style={{ width: '100px' }}>
                <AtomicButton onClick={() => history.goBack()}>Atrás</AtomicButton>
            </div>
            <br />
            <PageTitle title={`Consecutivo de compras: ${purchase.type} ${purchase.purchase_consec} `} />
            <h3>{`Status: ${purchase.status}`}</h3>
            <h3>{`Fecha: ${new Date(purchase.created_at).toLocaleDateString()}`}</h3>
            <h3>{`Provedor: ${purchase.company}`}</h3>
            <h3>{`Valor: ${purchase.value}`}</h3>
            <h3>Observaciones:</h3>
            <h3>{purchase.obs || 'Sin observaciones...'}</h3>
            <PageTitle title="Manejo de compra" />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <AtomicButton onClick={() => history.push(`/purchase-update/${purchase.id}`, purchase)}>Editar</AtomicButton>
                <AtomicButton onClick={() => history.push(`/purchase-update-progress/${purchase.id}`)}>Agregar progreso</AtomicButton>
                <AtomicButton onClick={() => history.push(`/purchase-print/${purchase.id}`, purchase)}>Generar O.C.</AtomicButton>
            </div>
            <PageTitle title="Progreso de compra" />
            {purchase.progress.length !== 0 && <Steps 
                direction="vertical"
                current={current}
                onChange={handleChange}
            >   
                {purchase.progress.map((step) => (
                    <Step
                        title={step.title}
                        subTitle={new Date(step.subTitle).toLocaleDateString()}
                        status={'finish'}
                        description={step.description}
                    />
                ))}
            </Steps>}
            {purchase.progress[current]?.file && <Base64PreviewMolecule file={purchase.progress[current]?.file} />}
        </div>
    )
};
