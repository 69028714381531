import React from 'react'
import { Col, Row } from 'antd';
import { IconMenuButton } from '../../organims/icon-menu-button';

const GeneralHomeFunctions = ({ page, funcionalidades  }) => {
    const funcionalidadesPermitidas = funcionalidades.filter(f => f.page.includes(page));
    return (
        <Row gutter={16}>
          <Col flex="1 0 10%">
            {funcionalidadesPermitidas.map((funcionalidad) => (<IconMenuButton key={funcionalidad.label} iconName={funcionalidad.iconName} label={funcionalidad.label} />))}
          </Col>
        </Row>
    )
}

export default GeneralHomeFunctions;