import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { MainForm } from './style';
import { ApiService } from '../../../services'
import { PageTitle } from '../../molecules/page-title';
import { FormikInput } from '../../molecules/formik-input-field';
import { AtomicButton } from '../../atoms/button';

export const TransportUpdatePage = (props) => {
    const history = useHistory();
    const params = useParams();
    const createPurchase = async (body) => {
        const { data } = await ApiService.TransportService.updateTransport(params.id, body);
        if(data.code === 200) history.push(`/transport/${data.data.transport.id}`)
    };
    return (
        <div>
            <div style={{ width: '100px' }}>
                <AtomicButton onClick={() => history.goBack()}>Atrás</AtomicButton>
            </div>
            <br />
            <PageTitle title={`Editar transporte:`} />
            <Formik
                initialValues={props.location.state}
                onSubmit={(data) => {
                    createPurchase(data);
                }}
                // validationSchema={yup.object().shape(schema)}
                >
                {({ handleSubmit, values }) => (
                    <MainForm onSubmit={handleSubmit} noValidate>
                        <FormikInput type="text" label="Consecutivo" name="movement_consec" />
                        <FormikInput type="text" label="Estatus" name="status" />
                        <FormikInput type="text" label="Transportadora" name="company" />
                        <FormikInput type="text" label="Centro de costo" name="type" />
                        <FormikInput type="number" label="Valor" name="value" />
                        <FormikInput type="checkbox" label="Itr" name="itr" />
                        <FormikInput type="checkbox" label="Retorno a origen" name="return2port" />
                        <FormikInput type="checkbox" label="Cargue" name="load" />
                        <FormikInput type="checkbox" label="Descargue" name="unload" />
                        <FormikInput type="text" label="Origen" name="origin" />
                        <FormikInput type="text" label="Destino" name="destination" />
                        <FormikInput type="number" label="Weight" name="weight" />
                        <div>
                            <AtomicButton onClick={handleSubmit}>Editar transporte</AtomicButton>
                        </div>
                    </MainForm>
                )}
            </Formik>
        </div>
    )
};
