import axios from 'axios';
import { getToken } from '../store/auth1/auth.selectors';
// import {  } from '../store/auth1/auth.actions';
import addOAuthInterceptor from 'axios-oauth-1.0a';


export const clients = {
  default: axios.create(),
  auth: axios.create(),
  marketing: axios.create(),
  woo: axios.create(),
  wooAWS: axios.create(),
};

export const applyInterceptors = (store) => {
  clients.default.interceptors.request.use(async (config) => {
    const newConfig = { ...config };
    const reduxState = store.getState();
    const authToken = getToken(reduxState);
    // console.log('authToken interceptor', authToken);
    if (authToken) {
      newConfig.headers.Authorization = `Bearer ${authToken}`;
    }
    return newConfig;
  });
  clients.default.interceptors.response.use(
    async (response) => {
      if (typeof response.data !== "object") {
        throw Error("Wrong data format from backend");
      }

      return response;
    },
    async function (error) {
      if (error.response.status === 401){
        console.log('Not Authenticated')// store.dispatch(logout());
      }
      return Promise.reject(error);
    }
  );

  // MARKETING -- AME CLIENT 
  clients.marketing.interceptors.request.use(async (config) => {
    const newConfig = { ...config };
    const reduxState = store.getState();
    const authToken = getToken(reduxState);
    // console.log('authToken interceptor', authToken);
    if (authToken) {
      newConfig.headers.Authorization = `Bearer ${authToken}`;
    }
    return newConfig;
  });
  clients.marketing.interceptors.response.use(
    async (response) => {
      if (typeof response.data !== "object") {
        throw Error("Wrong data format from backend");
      }

      return response;
    },
    async function (error) {
      if (error.response.status === 401){
        console.log('Not Authenticated')// store.dispatch(logout());
      }
      return Promise.reject(error);
    }
  );

  //WOO
  clients.woo.interceptors.request.use(async (config) => {
    const newConfig = { ...config };
    newConfig.auth = {
      username: 'ck_a8be1b731a79d937b1c64ccb914de9b7d97230ae', // 'ck_fb610b2dba5ced5530c3b914f301403aad5fc762', //
      password: 'cs_7b76c52f9fc4244a044a9d63eb69285b53c2d0bf' // 'cs_b77293db75338527834a5d6078d38d67a795ffc9', // 
    };
    return newConfig;
  });
  clients.woo.interceptors.response.use(
    async (response) => {
      if (typeof response.data !== "object") {
        throw Error("Wrong data format from backend");
      }

      return response;
    },
    async function (error) {
      if (error.response.status === 401){
        console.log('Not Authenticated')// store.dispatch(logout());
      }
      return Promise.reject(error);
    }
  );

  //WOOAWS
  // clients.wooAWS.interceptors.request.use(async (config) => {
  //   const newConfig = { ...config };
  //   newConfig.auth = {
  //     username: 'ck_fb610b2dba5ced5530c3b914f301403aad5fc762',
  //     password: 'cs_b77293db75338527834a5d6078d38d67a795ffc9'    
  //   };
    
  //   return newConfig;
  // });
  // clients.woo.interceptors.response.use(
  //   async (response) => {
  //     if (typeof response.data !== "object") {
  //       throw Error("Wrong data format from backend");
  //     }

  //     return response;
  //   },
  //   async function (error) {
  //     if (error.response.status === 401){
  //       console.log('Not Authenticated')// store.dispatch(logout());
  //     }
  //     return Promise.reject(error);
  //   }
  // );
};
