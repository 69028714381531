import styled from 'styled-components';
import { Table } from 'antd';

export const StyledTable = styled(Table)`
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    -webkit-background-clip: border-box;
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, 0.125);
    border-radius: 1rem;
    box-shadow: 0rem 1.25rem 1.6875rem 0rem rgb(0 0 0 / 5%);
    .ant-table-row-level-0 {
        width: 50%;
    }
`;

export const StyledRefMainRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;
export const StyledRefMainRowInnerImg = styled.div`
    width: 50%;
    height: 150px;
    overflow: hidden;
    border-radius: 50%;
    background: url(${props => props.img});
    margin-bottom: 1em;
    background-position: center;
    background-size: cover;
`;
export const StyledRefMainRowInnerInfo = styled.div`
    padding: 20px;
`;