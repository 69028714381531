
import { 
  // UserOutlined,
  InfoCircleOutlined,
  HomeOutlined,
  // SettingOutlined
  // MoneyCollectOutlined
  ShopOutlined
} from '@ant-design/icons';
export const menuOptions = [
  {
    name: 'Mi Perfil',
    route: '/me',
    icon: <InfoCircleOutlined />
  },
  {
    name: 'Inicio',
    route: '/',
    icon: <HomeOutlined />
  },
  {
    name: 'Productos',
    route: '/productos',
    icon: <ShopOutlined />
  }
  /* ,
  {
    name: 'Configs',
    route: '/config',
    icon: <SettingOutlined />
  } */
  /*{
    name: 'Inventario',
    route: '/stock',
    icon: <BoxPlotFilled />
  },
  {
    name: 'Directorio',
    route: '/users',
    icon: <UserOutlined />
  },
  {
    name: 'Proyectos',
    route: '/proyectos',
    icon: <UserOutlined />
  },
  {
    name: 'Activity',
    route: '/users',
    icon: <UserOutlined />
  },
  {
    name: 'Wallet',
    route: '/proyectos',
    icon: <UserOutlined />
  },
  {
    name: 'Ventas',
    route: '/sales',
    icon: <BoxPlotFilled />
  },
  {
    name: 'Logistica',
    route: '/logistics',
    icon: <BoxPlotFilled />
  },
  {
    name: 'Técnico',
    route: '/tecnico',
    icon: <BoxPlotFilled />
  },
  {
    name: 'Contabilidad',
    route: '/finance',
    icon: <BoxPlotFilled />
  } 
  {
    name: 'Configuraciones de plataforma',
    route: '/config',
    icon: <NotificationOutlined />
  }, */
];
