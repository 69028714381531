import styled from 'styled-components';
import { Card } from 'antd';
import { branding } from '../../../constants/info'

export const MainCardIndicatorStyled = styled(Card)`
    display: flex;
    flex-direction: column !important;
    border: 1px solid blue;
    border-radius: 5px;
    align-items: center;
    margin: 2px 0 5px 0;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 1px 2px rgb(0 0 0 / 23%);
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    -webkit-background-clip: border-box;
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, 0.125);
    border-radius: 1rem;
    box-shadow: 0rem 1.25rem 1.6875rem 0rem rgb(0 0 0 / 5%);
    width: -webkit-fill-available;
    & > .ant-card-body {
        width: -webkit-fill-available;
    }
    & > .ant-card-head {
        min-height: 12px !important;
    }
    .ant-card-head-title {
        min-height: none;
        padding: 0 3px;
    }
    .ant-card-body{
        padding: 0 3px;
    }
`;

export const StyledCardInner = styled.div`
padding: 16px;
    opacity: 1;
    background-color: rgb(255, 255, 255);
    color: rgb(52, 71, 103);
    display: flex;
    flex-direction: row;
    width: -webkit-fill-available;

`;

export const StyledCardInnerInfo = styled.div`
    box-sizing: border-box;
    margin: 0;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-basis: 66.666667%;
    -ms-flex-preferred-size: 66.666667%;
    flex-basis: 66.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 66.666667%;
    & > span {
        font-size: 14px;
        color: rgb(103, 116, 142);
        opacity: 1;
        text-transform: capitalize;
        margin: 0;
        font-family: "Roboto","Helvetica","Arial",sans-serif;
        font-size: 0.7rem;
        font-weight: 700;
        line-height: 1.5;
        text-transform: capitalize;
        letter-spacing: 0.02857em;
    }
    & > h1 {
        margin: 0;
        font-size: 0.9rem;
        line-height: 1.375;
        font-family: "Roboto","Helvetica","Arial",sans-serif;
        color: #344767;
        font-weight: 500;
        letter-spacing: 0em;
        & > span {
            margin: 0 0 0 2px;
            font-family: "Roboto","Helvetica","Arial",sans-serif;
            font-size: 0.7rem;
            font-weight: 700;
            line-height: 1.5;
            text-transform: capitalize;
            letter-spacing: 0.02857em;
            color: ${props => Math.sign(props.percent) > 0 ? '#86D616' : '#EA0606' }
        }
    }
`;

export const StyledCardInnerSymbol = styled.div`
    box-sizing: border-box;
    margin: 0;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333%;
`;

export const StyledCardInnerSymbolBox = styled.div`
        background-image: linear-gradient( 
            310deg,
            ${branding.primaryColor},
            ${branding.primaryBackgroundColor}
        );
        width: 3rem;
        height: 3rem;
        margin-left: auto;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        opacity: 1;
        background-color: ${branding.primaryColor};
        color: rgb(255, 255, 255);
        border-radius: 0.5rem;
        & > span > svg {
            height: 2em;
            width: 2em;
        }
`;