import React from 'react';
// import PropTypes from 'prop-types';
import { ContainerStyled } from "./styles";

export const AtomicButton = ({ children, width, onClick = () => alert('No Function') }) => {
    return (
        <ContainerStyled width={width} onClick={onClick} type="button">
            {children}
        </ContainerStyled>
    )
}


