import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, FieldArray } from 'formik';
import { MainForm } from './style';
import { ApiService } from '../../../services'
import { PageTitle } from '../../molecules/page-title';
import { FormikInput } from '../../molecules/formik-input-field';
import { AtomicButton } from '../../atoms/button';

export const PurchaseUpdatePage = (props) => {
    const history = useHistory();
    const params = useParams();
    const createPurchase = async (body) => {
        const { data } = await ApiService.PurchaseService.updatePurchase(params.id, body);
        if(data.code === 200) history.push(`/purchase/${data.data.purchase.id}`)
    };
    return (
        <div>
            <div style={{ width: '100px' }}>
                <AtomicButton onClick={() => history.goBack()}>Atrás</AtomicButton>
            </div>
            <br />
            <PageTitle title={`Editar compra:`} />
            <Formik
                initialValues={props.location.state}
                onSubmit={(data) => {
                    createPurchase(data);
                }}
                // validationSchema={yup.object().shape(schema)}
                >
                {({ handleSubmit, values }) => (
                    <MainForm onSubmit={handleSubmit} noValidate>
                        <FormikInput type="text" label="Proveedor" name="company" />
                        <FormikInput type="text" label="Estatus" name="status" />
                        <FormikInput type="text" label="Centro de costo" name="type" />
                        <FormikInput type="text" label="Valor" name="value" />
                        <FormikInput type="text" label="Observaciones" name="obs" />
                        <FieldArray
                            name="items"
                            render={arrayHelpers => (
                            <>
                                {values.items && values.items.length > 0 ? (
                                values.items.map((friend, index) => (
                                    <div style={{ width: '100%' }} key={index}>
                                    <label>{`Item: ${index + 1}`}</label>
                                    <FormikInput name={`items.${index}.ref`} label="Referencia" />
                                    <FormikInput name={`items.${index}.name`} label="Nombre" />
                                    <FormikInput name={`items.${index}.amount`} label="Cantidad" />
                                    <FormikInput name={`items.${index}.price`} label="Valor unitario" />
                                    <div style={{ display: 'flex'}}>
                                        <AtomicButton
                                            type="button"
                                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                        >
                                            Quitar Item
                                        </AtomicButton>
                                        <AtomicButton
                                            type="button"
                                            onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                        >
                                            Nuevo Item
                                        </AtomicButton>
                                    </div>
                                    </div>
                                ))
                                ) : (
                                <AtomicButton type="button" onClick={() => arrayHelpers.push('')}>
                                    {/* show this when user has removed all friends from the list */}
                                    Agregar Item
                                </AtomicButton>
                                )}
                            </>
                            )}
                        />
                        <div>
                            <AtomicButton onClick={handleSubmit}>Editar compra</AtomicButton>
                        </div>
                    </MainForm>
                )}
            </Formik>
        </div>
    )
};
