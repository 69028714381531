import { types } from "../types";

const initialState = {
  authenticated: false,
  token: null,
  userData: {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    createdAt: "",
    updatedAt: "",
    checkbox: "",
    title: "",
    role: "",
    developer: false,
    gerencia: false,
    tecnico: false,
    logistica: false,
    comercial: false,
    proveedor: false,
    cliente: false,
    picture: "",
    companyName: ""
  }
};

export const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.login:
      return {
        ...state,
        userData: payload,
        authenticated: true,
      };
    case types.token:
      return {
        ...state,
        token: payload,
        authenticated: true,
      };
    case types.logout:
      return initialState;
    case types.accountMessage:
      return {
        ...state,
        accountMessage: payload,
      };
    default:
      return state;
  }
};


/* 
  authenticated: true,
  token: 'jkhgkjhsdituytsad',
  userData: {
    _id: "6126b3f8162f563054eecbc0",
    firstName: "Milko",
    lastName: "Castaneda",
    email: "allenedsdmilko@gmail.com",
    phone: "3022538905",
    city: null,
    country: null,
    receiveEmails: false,
    acceptedPolicies: [],
    createdAt: "2021-08-25T21:19:52.747Z",
    updatedAt: "2021-08-27T18:56:39.015Z",
    accountMessage: "",
    plan: "full",
    followers: [],
    testingFree: true,
    wallet: "",
    isActive: false,
    addresses: [
        "612931e42c30616e943bbd72",
        "612932052c30616e943bbd73",
        "61293374bf72f82c0c1397bf",
        "612934dcb662b1299c8c839e",
        "612935663f144a5aec20b30f"
    ],
    recoveryCode: null,
    balanceAvailable: 0
  }

  authenticated: false,
  token: null,
  userData: {
    _id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    city: null,
    country: null,
    receiveEmails: false,
    acceptedPolicies: [],
    recoveryCode: null,
    createdAt: "",
    updatedAt: "",
    accountMessage: null,
    plan: null,
    followers: [],
    testingFree: false,
    wallet: null,
    isActive: false,
    addresses: [],
    recoveryCode: null,
    balanceAvailable: 0
  }



*/