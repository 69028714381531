import React from 'react';
import { useDispatch } from 'react-redux';
import { menuOptions } from './options-menu';
import { StyledMenuItem, StyledIconDiv } from './styles';
import { push } from 'connected-react-router';

export const PrincipalSideMenu = () => {
  const dispatch = useDispatch();
  const pushHistory = (route) => () => {
    dispatch(push(route));
  };
  return (
    <StyledMenuItem style={{}}>
      {menuOptions.map((item, index) => (
        <StyledMenuItem.Item
          key={index}
          onClick={pushHistory(item.route)}
        >
          <StyledIconDiv>
            {item.icon}
          </StyledIconDiv>
          <p>{item.name}</p>
        </StyledMenuItem.Item>
      ))}
    </StyledMenuItem>
  );
};
