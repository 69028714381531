import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      'Inicio': 'Welcome',
      'Transportes': 'Transport archive',
      'Compras': 'Purchasing Archive',
      'Menu': 'Functionality Menu',
      'UserFuncs': 'Functionality Menu',
      'Ventas': 'Saless',
      'Prospectos': 'Prospects',
      'Cotizaciones': 'Quotes',
      'Proyectos': 'Proyects',
      'Facturación': 'Invoices',
      'Cartera': 'Wallet',
      'Comercial #1': 'Sales #1',
      'Inventario': 'Inventory',
      'Obras': 'Proyects',
      'Intro Ventas': 'Sales introductions...',
      'Lista de prospectos':'Prospects Lists'
    },
  },
  es: {
    translation: {
      'Inicio': 'Bienvenido',
      'Transportes': 'Archivo de transportes',
      'Compras': 'Archivo de compras',
      'Menu': 'Menú de funcionalidades',
      'UserFuncs': 'Menu de funcionalidades',
      'Ventas': 'Ventas',
      'Prospectos': 'Prospectos',
      'Cotizaciones': 'Cotizaciones',
      'Proyectos': 'Proyectos',
      'Facturación': 'Facturación',
      'Cartera': 'Cartera',
      'Comercial #1': 'Comercial #1',
      'Inventario': 'Inventario',
      'Obras': 'Obras',
      'Intro Ventas': 'Aquí se pueden ver estadísticas y metricas del departamento de Ventas en Panda Lab S.A.S.',
      'Lista de prospectos':'Lista de prospectos'
    },
  },
  swe: {
    translation: {
      'Inicio': 'Välkommen',
      'Transportes': 'Transportarkiv',
      'Compras': 'Inköpsfil',
      'Menu': 'Funktionsmeny',
      'UserFuncs': 'Funktionsmeny',
      'Ventas': 'Försäljning',
      'Prospectos': 'Prospekt',
      'Cotizaciones': 'Ambud',
      'Proyectos': 'Projekt',
      'Facturación': 'Fakturering',
      'Cartera': 'Väntar på betalning',
      'Comercial #1': 'Säljare #1',
      'Inventario': 'Lager',
      'Obras': 'Projekter',
      'Intro Ventas': 'Här kan du se statistik och mätvärden för försäljningsavdelningen på Panda Lab S.A.S.',
      'Lista de prospectos':'Prospekt Lista'
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'es', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
