export const projectList = [
    {
      name: 'Food Truck Wrap',
      company: 'Rocked & Loaded',
      type: 'Suministro/Installación',
      projectType: 'Graphics',
      supervisor: 'Robin Reyes',
      designer: 'Andrea',
      installer: 'Holfman',
      status: 'Contacto'
    },
    {
      name: 'Water Mold Fire',
      company: 'Transit Wrap',
      type: 'Suministro',
      projectType: 'Graphics',
      supervisor: 'Andrea',
      designer: 'Felipe',
      installer: 'Holfman, Salvatore',
      status: 'Cotizado'
    },
    {
      name: 'All Star Coaches',
      company: 'Trailer Wrap',
      type: 'Suministro/Installación',
      projectType: 'Wrap',
      supervisor: 'Robin Reyes',
      designer: 'Ingrid',
      installer: 'Holfman',
      status: 'Recolección'
    },
    {
      name: 'Water Mold Fire',
      company: 'Transit Wrap',
      type: 'Suministro',
      projectType: 'Graphics',
      supervisor: 'Robin Reyes',
      designer: 'Felipe',
      installer: 'Holfman, Salvatore',
      status: 'Cotizado'
    },
    {
      name: 'All Star Coaches',
      company: 'Trailer Wrap',
      type: 'Suministro/Installación',
      projectType: 'Wrap',
      supervisor: 'Robin Reyes',
      designer: 'Adam',
      installer: 'Holfman',
      status: 'Recolección'
    },
    {
      name: 'Water Mold Fire',
      company: 'Transit Wrap',
      type: 'Suministro',
      projectType: 'Graphics',
      supervisor: 'Robin Reyes',
      designer: 'Felipe',
      installer: 'Holfman, Salvatore',
      status: 'Cotizado'
    },
    {
      name: 'All Star Coaches',
      company: 'Trailer Wrap',
      type: 'Suministro/Installación',
      projectType: 'Wrap',
      supervisor: 'Robin Reyes',
      designer: 'Adam',
      installer: 'Holfman',
      status: 'Recolección'
    },
    {
      name: 'Water Mold Fire',
      company: 'Transit Wrap',
      type: 'Suministro',
      projectType: 'Graphics',
      supervisor: 'Robin Reyes',
      designer: 'Felipe',
      installer: 'Holfman, Salvatore',
      status: 'Vendido'
    },
    {
      name: 'All Star Coaches',
      company: 'Trailer Wrap',
      type: 'Suministro/Installación',
      projectType: 'Wrap',
      supervisor: 'Robin Reyes',
      designer: 'Adam',
      installer: 'Holfman',
      status: 'Recolección'
    },
    {
      name: 'Water Mold Fire',
      company: 'Transit Wrap',
      type: 'Suministro',
      projectType: 'Graphics',
      supervisor: 'Robin Reyes',
      designer: 'Felipe',
      installer: 'Holfman, Salvatore',
      status: 'Vendido'
    },
    {
      name: 'All Star Coaches',
      company: 'Trailer Wrap',
      type: 'Suministro/Installación',
      projectType: 'Wrap',
      supervisor: 'Robin Reyes',
      designer: 'Adam',
      installer: 'Holfman',
      status: 'Recolección'
    },
    {
      name: 'Water Mold Fire',
      company: 'Transit Wrap',
      type: 'Suministro',
      projectType: 'Graphics',
      supervisor: 'Robin Reyes',
      designer: 'Felipe',
      installer: 'Holfman, Salvatore',
      status: 'Vendido'
    },
    {
      name: 'All Star Coaches',
      company: 'Trailer Wrap',
      type: 'Suministro/Installación',
      projectType: 'Wrap',
      supervisor: 'Robin Reyes',
      designer: 'Adam',
      installer: 'Holfman',
      status: 'Recolección'
    },
    {
      name: 'Water Mold Fire',
      company: 'Transit Wrap',
      type: 'Suministro',
      projectType: 'Graphics',
      supervisor: 'Robin Reyes',
      designer: 'Felipe',
      installer: 'Holfman, Salvatore',
      status: 'Vendido'
    },
    {
      name: 'All Star Coaches',
      company: 'Trailer Wrap',
      type: 'Suministro/Installación',
      projectType: 'Wrap',
      supervisor: 'Robin Reyes',
      designer: 'Adam',
      installer: 'Holfman',
      status: 'Recolección'
    },
    {
      name: 'Water Mold Fire',
      company: 'Transit Wrap',
      type: 'Suministro',
      projectType: 'Graphics',
      supervisor: 'Robin Reyes',
      designer: 'Felipe',
      installer: 'Holfman, Salvatore',
      status: 'Instalando'
    },
    {
      name: 'All Star Coaches',
      company: 'Trailer Wrap',
      type: 'Suministro/Installación',
      projectType: 'Wrap',
      supervisor: 'Robin Reyes',
      designer: 'Adam',
      installer: 'Holfman',
      status: 'Recolección'
    },
    {
      name: 'Water Mold Fire',
      company: 'Transit Wrap',
      type: 'Suministro',
      projectType: 'Graphics',
      supervisor: 'Robin Reyes',
      designer: 'Felipe',
      installer: 'Holfman, Salvatore',
      status: 'Instalando'
    },
    {
      name: 'All Star Coaches',
      company: 'Trailer Wrap',
      type: 'Suministro/Installación',
      projectType: 'Wrap',
      supervisor: 'Robin Reyes',
      designer: 'Adam',
      installer: 'Holfman',
      status: 'Recolección'
    },
    {
      name: 'Water Mold Fire',
      company: 'Transit Wrap',
      type: 'Suministro',
      projectType: 'Graphics',
      supervisor: 'Robin Reyes',
      designer: 'Felipe',
      installer: 'Holfman, Salvatore',
      status: 'Instalando'
    },
    {
      name: 'All Star Coaches',
      company: 'Trailer Wrap',
      type: 'Suministro/Installación',
      projectType: 'Wrap',
      supervisor: 'Robin Reyes',
      designer: 'Adam',
      installer: 'Holfman',
      status: 'Recolección'
    },
    {
      name: 'Water Mold Fire',
      company: 'Transit Wrap',
      type: 'Suministro',
      projectType: 'Graphics',
      supervisor: 'Robin Reyes',
      designer: 'Felipe',
      installer: 'Holfman, Salvatore',
      status: 'Instalando'
    },
    {
      name: 'All Star Coaches',
      company: 'Trailer Wrap',
      type: 'Suministro/Installación',
      projectType: 'Wrap',
      supervisor: 'Robin Reyes',
      designer: 'Adam',
      installer: 'Holfman',
      status: 'Recolección'
    },
    {
      name: 'Water Mold Fire',
      company: 'Transit Wrap',
      type: 'Suministro',
      projectType: 'Graphics',
      supervisor: 'Robin Reyes',
      designer: 'Felipe',
      installer: 'Holfman, Salvatore',
      status: 'Cotizado'
    },
    {
      name: 'All Star Coaches',
      company: 'Trailer Wrap',
      type: 'Suministro/Installación',
      projectType: 'Wrap',
      supervisor: 'Robin Reyes',
      designer: 'Adam',
      installer: 'Holfman',
      status: 'Recolección'
    },
    {
      name: 'Water Mold Fire',
      company: 'Transit Wrap',
      type: 'Suministro',
      projectType: 'Graphics',
      supervisor: 'Robin Reyes',
      designer: 'Felipe',
      installer: 'Holfman, Salvatore',
      status: 'Cotizado'
    },
    {
      name: 'All Star Coaches',
      company: 'Trailer Wrap',
      type: 'Suministro/Installación',
      projectType: 'Wrap',
      supervisor: 'Robin Reyes',
      designer: 'Adam',
      installer: 'Holfman',
      status: 'Recolección'
    },
    {
      name: 'Water Mold Fire',
      company: 'Transit Wrap',
      type: 'Suministro',
      projectType: 'Graphics',
      supervisor: 'Robin Reyes',
      designer: 'Felipe',
      installer: 'Holfman, Salvatore',
      status: 'Cotizado'
    },
    {
      name: 'All Star Coaches',
      company: 'Trailer Wrap',
      type: 'Suministro/Installación',
      projectType: 'Wrap',
      supervisor: 'Robin Reyes',
      designer: 'Adam',
      installer: 'Holfman',
      status: 'Material Entregado'
    },
    {
      name: 'Water Mold Fire',
      company: 'Transit Wrap',
      type: 'Suministro',
      projectType: 'Graphics',
      supervisor: 'Robin Reyes',
      designer: 'Felipe',
      installer: 'Holfman, Salvatore',
      status: 'Cotizado'
    },
    {
      name: 'All Star Coaches',
      company: 'Trailer Wrap',
      type: 'Suministro/Installación',
      projectType: 'Wrap',
      supervisor: 'Robin Reyes',
      designer: 'Adam',
      installer: 'Holfman',
      status: 'Material Entregado'
    },
    {
      name: 'Water Mold Fire',
      company: 'Transit Wrap',
      type: 'Suministro',
      projectType: 'Graphics',
      supervisor: 'Robin Reyes',
      designer: 'Felipe',
      installer: 'Holfman, Salvatore',
      status: 'Cotizado'
    },
    {
      name: 'All Star Coaches',
      company: 'Trailer Wrap',
      type: 'Suministro/Installación',
      projectType: 'Wrap',
      supervisor: 'Robin Reyes',
      designer: 'Adam',
      installer: 'Holfman',
      status: 'Material Entregado'
    },
    {
      name: 'Water Mold Fire',
      company: 'Transit Wrap',
      type: 'Suministro',
      projectType: 'Graphics',
      supervisor: 'Robin Reyes',
      designer: 'Felipe',
      installer: 'Holfman, Salvatore',
      status: 'Cotizado'
    },
    {
      name: 'All Star Coaches',
      company: 'Trailer Wrap',
      type: 'Suministro/Installación',
      projectType: 'Wrap',
      supervisor: 'Robin Reyes',
      designer: 'Adam',
      installer: 'Holfman',
      status: 'Material Entregado'
    },
    {
      name: 'Water Mold Fire',
      company: 'Transit Wrap',
      type: 'Suministro',
      projectType: 'Graphics',
      supervisor: 'Robin Reyes',
      designer: 'Felipe',
      installer: 'Holfman, Salvatore',
      status: 'Cotizado'
    },
    {
      name: 'All Star Coaches',
      company: 'Trailer Wrap',
      type: 'Suministro/Installación',
      projectType: 'Wrap',
      supervisor: 'Robin Reyes',
      designer: 'Adam',
      installer: 'Holfman',
      status: 'Material Entregado'
    },
    {
      name: 'Water Mold Fire',
      company: 'Transit Wrap',
      type: 'Suministro',
      projectType: 'Graphics',
      supervisor: 'Robin Reyes',
      designer: 'Felipe',
      installer: 'Holfman, Salvatore',
      status: 'Instalando'
    },
    {
      name: 'All Star Coaches',
      company: 'Trailer Wrap',
      type: 'Suministro/Installación',
      projectType: 'Wrap',
      supervisor: 'Robin Reyes',
      designer: 'Adam',
      installer: 'Holfman',
      status: 'Material Entregado'
    },
    {
      name: 'Water Mold Fire',
      company: 'Transit Wrap',
      type: 'Suministro',
      projectType: 'Graphics',
      supervisor: 'Robin Reyes',
      designer: 'Felipe',
      installer: 'Holfman, Salvatore',
      status: 'Instalando'
    },
    {
      name: 'All Star Coaches',
      company: 'Trailer Wrap',
      type: 'Suministro/Installación',
      projectType: 'Wrap',
      supervisor: 'Robin Reyes',
      designer: 'Adam',
      installer: 'Holfman',
      status: 'Material Entregado'
    },
    {
      name: 'Water Mold Fire',
      company: 'Transit Wrap',
      type: 'Suministro',
      projectType: 'Graphics',
      supervisor: 'Robin Reyes',
      designer: 'Felipe',
      installer: 'Holfman, Salvatore',
      status: 'Instalando'
    },
    {
      name: 'All Star Coaches',
      company: 'Trailer Wrap',
      type: 'Suministro/Installación',
      projectType: 'Wrap',
      supervisor: 'Robin Reyes',
      designer: 'Adam',
      installer: 'Holfman',
      status: 'Material Entregado'
    },
    {
      name: 'Water Mold Fire',
      company: 'Transit Wrap',
      type: 'Suministro',
      projectType: 'Graphics',
      supervisor: 'Robin Reyes',
      designer: 'Felipe',
      installer: 'Holfman, Salvatore',
      status: 'Instalando'
    },
    {
      name: 'All Star Coaches',
      company: 'Trailer Wrap',
      type: 'Suministro/Installación',
      projectType: 'Wrap',
      supervisor: 'Robin Reyes',
      designer: 'Adam',
      installer: 'Holfman',
      status: 'Material Entregado'
    },
    {
      name: 'Water Mold Fire',
      company: 'Transit Wrap',
      type: 'Suministro',
      projectType: 'Graphics',
      supervisor: 'Robin Reyes',
      designer: 'Felipe',
      installer: 'Holfman, Salvatore',
      status: 'Instalando'
    },
    {
      name: 'All Star Coaches',
      company: 'Trailer Wrap',
      type: 'Suministro/Installación',
      projectType: 'Wrap',
      supervisor: 'Robin Reyes',
      designer: 'Adam',
      installer: 'Holfman',
      status: 'Material Entregado'
    },
    {
      name: 'Water Mold Fire',
      company: 'Transit Wrap',
      type: 'Suministro',
      projectType: 'Graphics',
      supervisor: 'Robin Reyes',
      designer: 'Felipe',
      installer: 'Holfman, Salvatore',
      status: 'Vendido'
    },
    {
      name: 'All Star Coaches',
      company: 'Trailer Wrap',
      type: 'Suministro/Installación',
      projectType: 'Wrap',
      supervisor: 'Robin Reyes',
      designer: 'Adam',
      installer: 'Holfman',
      status: 'Material Entregado'
    },
    {
      name: 'Water Mold Fire',
      company: 'Transit Wrap',
      type: 'Suministro',
      projectType: 'Graphics',
      supervisor: 'Robin Reyes',
      designer: 'Felipe',
      installer: 'Holfman, Salvatore',
      status: 'Vendido'
    },
    {
      name: 'All Star Coaches',
      company: 'Trailer Wrap',
      type: 'Suministro/Installación',
      projectType: 'Wrap',
      supervisor: 'Robin Reyes',
      designer: 'Adam',
      installer: 'Holfman',
      status: 'Material Entregado'
    }
  ]