import React from 'react';
import { useEffect } from 'react';
import { useDispatch/* , useSelector */ } from 'react-redux';
import { useParams } from 'react-router';

import { TableCustom } from '../../organims/table';
// import { columnsHistory } from './data-columns';
import {
  cleanWallet,
  getAllWallets,
  getWalletHistoriesOneUser,
} from '../../../store/wallet/wallet.actions';
import { PageTitle } from '../../molecules/page-title';
// import { getAllhistorySelector } from '../../../store/wallet/wallet.selectors';

export const TranstacionsPage = () => {
  const { id: idWallet } = useParams();
  const dispatch = useDispatch();
  // const dataWallets = useSelector(getAllhistorySelector);

  useEffect(() => {
    console.log(idWallet);
    if (idWallet) {
      dispatch(getWalletHistoriesOneUser(idWallet));
    } else {
      dispatch(getAllWallets());
    }
    return () => {
      dispatch(cleanWallet());
    };
  }, [dispatch, idWallet]);

  return (
    <div>
      <PageTitle title={idWallet ? `Transacciones del usuario: ${idWallet}` : 'Transacciones'} />
      <p>En transacciones vamos a poder administrar y analizar acciones con nuestra billetera. Consolidar pagos y debitos en las billeteras internas.</p>
      <TableCustom columns={columns} data={data} />
    </div>
  );
};

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: text => <a href="www.pandalab.com.co">{text}</a>,
    width: 150,
  },
  {
    title: 'Age',
    dataIndex: 'age',
    key: 'age',
    width: 80,
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address 1',
    ellipsis: true,
  },
  {
    title: 'Long Column Long Column Long Column',
    dataIndex: 'address',
    key: 'address 2',
    ellipsis: true,
  },
  {
    title: 'Long Column Long Column',
    dataIndex: 'address',
    key: 'address 3',
    ellipsis: true,
  },
  {
    title: 'Long Column',
    dataIndex: 'address',
    key: 'address 4',
    ellipsis: true,
  },
];

const data = [
  {
    key: '1',
    name: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park, New York No. 1 Lake Park',
    tags: ['nice', 'developer'],
  },
  {
    key: '2',
    name: 'Jim Green',
    age: 42,
    address: 'London No. 2 Lake Park, London No. 2 Lake Park',
    tags: ['loser'],
  },
  {
    key: '3',
    name: 'Joe Black',
    age: 32,
    address: 'Sidney No. 1 Lake Park, Sidney No. 1 Lake Park',
    tags: ['cool', 'teacher'],
  },
];