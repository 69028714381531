export class StockService {
  constructor(client) {
    this.client = client;
  }

  healthCheck() {
    return this.client.get('/stock/healthcheck');
  }

  getStock(){
    return this.client.get('/stock/getStock');
  }

  getItems(){
    return this.client.get('/stockmovement/getItems');
  }
  
  getStockById(id){
    return this.client.get(`/stock/getStockById?id=${id}`)
  }
   
  getItemByNum(id){
    return this.client.get(`/stockmovement/getItemById?num=${id}`)
  } 

  updateVerifiedItemById(id){
    return this.client.post(`/stockmovement/updateVerifiedItemById?num=${id}`);
  }

  createMovement(body){
    return this.client.post('/stockmovement/createMovement', body);
  }

  createStockRef(body){
    return this.client.post(`/stock/createStock`, body)
  }

  editStockRef(id, body){
    return this.client.post(`stock/updateStockById?id=${id}`, body);
  }

  getMovements(){
    return this.client.get('/stockmovement/getMovements');
  }
  
  updateTransport(id, body){
    return this.client.post(`/transport/updateTransport?id=${id}`, body)
  }
  
  createStockRefWithCSV(body){
    const bodyFormData = new FormData();
    body.file !== null && bodyFormData.append('data', body.file);
    return this.client.post(`/stock/createStockInBulk`, bodyFormData, {headers: { "Content-Type": "multipart/form-data" }})
  }
}
