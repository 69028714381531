import React from 'react';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { AtomicButton } from '../../atoms/button';

export const BarcodeScannerOrganism = ({ setData }) => {
    const [light, setLight] = React.useState(false);
    return (
      <>
        <BarcodeScannerComponent
          width={300}
          height={300}
          torch={light}
          onUpdate={(err, result) => {
            if (result) {
                setData(result.text)
            } else 
            setData("Not Found");
          }}
        />
        <AtomicButton onClick={() => setLight(!light)}>Prender luz</AtomicButton>
      </>
    );
};
