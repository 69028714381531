import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TableCustom } from '../../organims/table';
import { MainCardIndicator } from '../../molecules/main-card-indicator';
import { WrapperShipments } from './styled';
import { PageTitle } from '../../molecules/page-title';
import { Tag, Space, Button, Row,  Col } from 'antd';
import { projectList } from '../../../constants/projectData';
import GeneralHomeFunctions from '../../organims/general-home-functions';
import { funcionalidades } from '../../../constants/funcionalidades';
// the hook
import { useTranslation } from 'react-i18next';
import { getUserData } from '../../../store/auth/auth.selectors';

export const Ventas = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { firstName } = useSelector(getUserData);

  const columns = [
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company'
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      render: (text, record) => (
        <Tag color='blue'>
          {record.status.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button onClick={() => history.push('/wrap-details')}>Ver detalles</Button>
        </Space>
      ),
    },
  ];
  const indicators = [
    {
      title: t('Prospectos'),
      content: '15MM',
      crecimiento: -2,
      acendente: true,
      icon: 'ArrowDownOutlined'
    },
    {
      title: t('Cotizaciones'),
      content: '30MM',
      crecimiento: -2,
      acendente: true,
      icon: 'ArrowUpOutlined'
    },
    {
      title: t('Proyectos'),
      content: '13MM',
      crecimiento: 2,
      acendente: true,
      icon: 'WalletOutlined'
    },
    {
      title: t('Facturación'),
      content: '33',
      crecimiento: -2,
      acendente: true,
      icon: 'ProfileOutlined'
    },
    {
      title: t('Cartera'),
      content: '13',
      crecimiento: 2,
      acendente: false,
      icon: 'AreaChartOutlined'
    },
    {
      title: t('Comercial #1'),
      content: 'Ingrid B.',
      crecimiento: 1,
      acendente: true,
      icon: 'RiseOutlined'
    }
  ];
  return (
    <WrapperShipments>
      <PageTitle title={`${t('Ventas')} ${firstName}`} />
      <Row gutter={16}>
        {indicators.map(indicator => (
          <Col flex="1 0 30%">
            <MainCardIndicator
              bordered={false}
              indicator={indicator}
            />
        </Col>
        ))}
      </Row>
      <p>{t('Intro Ventas')}</p>
      <GeneralHomeFunctions page="ventas" funcionalidades={funcionalidades} />
      <Row>
        <h1>Obras (Prospección - Finalizar Obra):</h1>{' '}
        <Button onClick={() => alert('Crear una salida del inventario')}>Crear projecto nuevo</Button>
      </Row>
      <TableCustom
        columns={columns} 
        data={projectList} 
        searchableTable={true}
        searchablePlaceholder="Obra"  
        serachableObjKey='name'
      />
      <Row>
        <h1>Reservas:</h1>{' '}
        <Button onClick={() => alert('Crear una reserva del inventario')}>Crear reserva nueva</Button>
      </Row>
      <TableCustom
        columns={columns} 
        data={projectList} 
        searchableTable={true}
        searchablePlaceholder="Obra"  
        serachableObjKey='name'
      />
      <Row>
        <h1>Entradas:</h1>
        <p>(Las entradas para el departamento comercial son informativos y no los pueden modificar.)</p>
      </Row>
      <TableCustom
        columns={columns} 
        data={projectList} 
        searchableTable={true}
        searchablePlaceholder="Obra"  
        serachableObjKey='name'
      />
      <Row>
        <h1>Salidas:</h1>
        <p>(Las salidas para el departamento comercial son informativos y no los pueden modificar.)</p>
      </Row>
      <TableCustom
        columns={columns} 
        data={projectList} 
        searchableTable={true}
        searchablePlaceholder="Obra"  
        serachableObjKey='name'
      />
      <Row>
        <h1>Facturas:</h1>{' '}
        <Button onClick={() => alert('Crear una reserva del inventario')}>Crear reserva nueva</Button>
      </Row>
      <TableCustom
        columns={columns} 
        data={projectList} 
        searchableTable={true}
        searchablePlaceholder="Obra"  
        serachableObjKey='name'
      />
    </WrapperShipments>
  );
};
