import styled from 'styled-components';

export const ContainerStyled = styled.div`
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-flex-direction: row;
-ms-flex-direction: row;
flex-direction: row;
border: 1px solid blue;
border-radius: 5px;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
margin: 2px 0 5px 0;
box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 1px 2px rgb(0 0 0 / 23%);
background-color: #fff;
color: rgba(0,0,0,0.87);
-webkit-transition: box-shadow 300ms cubic-bezier(0.4,0,0.2,1) 0ms;
-webkit-transition: box-shadow 300ms cubic-bezier(0.4,0,0.2,1) 0ms;
transition: box-shadow 300ms cubic-bezier(0.4,0,0.2,1) 0ms;
border-radius: 4px;
box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-flex-direction: row;
-ms-flex-direction: row;
-webkit-flex-direction: row;
-ms-flex-direction: row;
flex-direction: row;
position: relative;
min-width: 0;
word-wrap: break-word;
background-color: #ffffff;
-webkit-background-clip: border-box;
background-clip: border-box;
border: 0 solid rgba(0,0,0,0.125);
border-radius: 1rem;
box-shadow: 0rem 1.25rem 1.6875rem 0rem rgb(0 0 0 / 5%);
width: -webkit-fill-available;
justify-content: space-evenly;
flex-wrap: wrap;
`;