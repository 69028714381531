import React from 'react';
// import PropTypes from 'prop-types';
import { ContainerStyled } from "./styles";

export const AtomicCard = ({ children }) => {
    return (
        <ContainerStyled>
            {children}
        </ContainerStyled>
    )
}


