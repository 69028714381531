import { applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';

import { ApiService } from '../services';
import { showToast } from '../utils/show-message';

const showError = () => (next) => (action) => {
  let result = next(action);

  const { error } = result || { error: null };
  if (error) {
    const { id, close, message } = error || {
      id: 'default',
      close: null,
      message: null,
    };
    const messageCustom = message || 'ha ocurrido un error!!!';
    showToast({
      id: id,
      autoClose: close,
      message: `\u{1F613} ${messageCustom}`,
      type: 'error',
    });
  }
  return result;
};
const showSuccess = () => (next) => (action) => {
  let result = next(action);

  const { success } = result || { error: null };
  if (success) {
    const { id, close, message } = success || {
      id: 'default',
      close: 1500,
      message: null,
    };
    const messageCustom = message || 'Accion realizada con exito!!!';
    showToast({
      id: id,
      autoClose: close,
      message: messageCustom,
      type: 'success',
    });
  }
  return result;
};
const enhancers = [thunk.withExtraArgument(ApiService), showError, showSuccess];

export const AppMiddlewares = (history) => {
  enhancers.push(routerMiddleware(history));
  let middlewares = compose(applyMiddleware(...enhancers));
  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV === 'development') {
    middlewares = composeWithDevTools(middlewares);
  }
  return middlewares;
};
