import React from 'react';
import { Layout, Menu } from 'antd';
import { Drawer, Button } from "antd";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from 'react-redux';
import { StyledBody, StyledBody2, StyledAside, StyledFooter, StyledNav, StyledMainUser } from './styles';
import { Logo } from "../../atoms/logo";
import logo from '../../../assets/img/logo.png';
import logoSmall from '../../../assets/img/favicon.ico';
import { getUserData } from '../../../store/auth/auth.selectors';

import { 
  UserOutlined
} from '@ant-design/icons';

import { logout } from '../../../store/auth/auth.actions'
// import { getAuth } from '../../../store/auth/auth.selectors';

export const MainLayout = ({ sideMenu, children }) => {
  const dispatch = useDispatch();
  const user = useSelector(getUserData)
  const [collapsedState, setCollapsedState] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  return (
    <Layout style={{ Height: '100vh' }}>
      <StyledAside       
        // className="sidebar"
        // breakpoint={"lg"}
        // theme="light"
        // collapsible
        // collapsedWidth={0}
        // trigger={null}
        onCollapse={(collapsed, type) => {
          setCollapsedState(collapsed)
        }}
      >
        <div className="header-side">
          <Logo srcUrl={collapsedState ? logoSmall : logo} alt="logo-shipal" heigth="44" />
        </div>
        <StyledMainUser>
          <p>{`${user.firstName} ${user.lastName}`}</p>
          <p>{user.title}</p>
          <p>{user.compnayName}</p>
        </StyledMainUser>
        {sideMenu}
        <div style={{}} />
        <StyledFooter>
          <Menu style={{}}>
            <Menu.Item onClick={() => dispatch(logout())} key="9" icon={<UserOutlined />}>
                  Cerrar Cesión
            </Menu.Item>
          </Menu>
        </StyledFooter>
      </StyledAside>
      <Layout className="site-layout" >
        <StyledNav>
        <Button
          style={{
            color: '#000',
            backgroundColor: 'transparent',
            border: 0,
            height: '50px',
            width: '50px',
            boxShadow: 'none'
          }}
          className="menu"
          type="primary"
          icon={<MenuUnfoldOutlined />}
          onClick={() => setVisible(true)}
        />
        <Drawer
          placement="left"
          onClick={() => setVisible(false)}
          onClose={() => setVisible(false)}
          visible={visible}
        > 
        <div className="header-side">
          <Logo srcUrl={collapsedState ? logoSmall : logo} alt="logo-bylin" heigth="44" />
        </div>
        {sideMenu}
        <div style={{}} />
        <StyledFooter>
          <Menu style={{}}>
            <Menu.Item onClick={() => dispatch(logout())} key="9" icon={<UserOutlined />}>
                  Cerrar Cesión
            </Menu.Item>
          </Menu>
        </StyledFooter>
      </Drawer>
      <a href="/"><img src={logo} className="logo" alt="logo" height="44" /></a>     
      </StyledNav>
        <StyledBody>
          <StyledBody2>
            {children}
          </StyledBody2>
        </StyledBody>
      </Layout>
    </Layout>
  );
};
