import React, { useEffect, useState } from 'react'
import { Tag } from 'antd';
import { ApiService } from '../../../services';
import { useTranslation } from 'react-i18next';
// import { useHistory } from 'react-router-dom';
import { Transition } from '../general-transition';
import { PageTitle } from '../../molecules/page-title';
import { TableCustom } from '../table';
import { AtomicButton } from '../../atoms/button';
import { LoaderAtom } from '../../atoms/loading';

export const EmailListModule = () => {
  const [leadList, setLeadList] = useState(null);
    const { t } = useTranslation();
    // const history = useHistory();
    const getModuleData = async () => {
      const { data } = await ApiService.MarketingService.getConfigService();
      // console.log(data);
      setLeadList(data.data);
    };
    useEffect(() => getModuleData(), [])
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        render: (text, record) => (
          <p>
            {record.firstName || record.lastName ? `${record?.firstName || record.lastName}` : 'N/A'}
          </p>
        ),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        render: (text, record) => (
          <Tag color='green'>
            {record?.email?.toUpperCase()}
          </Tag>
        ),
      },
      {
        title: 'Fecha',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text) => new Date(text).toLocaleDateString()
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <AtomicButton onClick={() => alert(`User Id${record.id}`)}>+ Info</AtomicButton>
        ),
        fixed: 'right'
      }
    ];
    if(!leadList) return <LoaderAtom />;
    return (
        <Transition>
            <PageTitle title={t('Lista de prospectos')} />
            {/* <AtomicButton onClick={() => history.push(`/nuevo-transport`)}>Nuevo transporte</AtomicButton> */}
            <TableCustom
                columns={columns} 
                data={leadList} 
                searchableTable={false}
                searchablePlaceholder=""  
                serachableObjKey='movement_consec'
            />
        </Transition>
    )
};