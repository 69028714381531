export class TransportService {
  constructor(client) {
    this.client = client;
  }

  healthCheck() {
    return this.client.get('/transport/healthcheck');
  }

  getTransports(){
    return this.client.get('/transport/getTransports');
  }
  
  getTransportById(id){
    return this.client.get(`/transport/getTransportById?id=${id}`)
  }
  
  createTransport(body){
    return this.client.post(`/transport/createTransport`, body)
  }
  
  updateTransport(id, body){
    return this.client.post(`/transport/updateTransport?id=${id}`, body)
  }
  
  updateTransportProgress(id, body){
    const bodyFormData = new FormData();
    bodyFormData.append('updatedBy', body.updatedBy);
    bodyFormData.append('title', body.title);
    bodyFormData.append('status', body.status);
    bodyFormData.append('description', body.description);
    body.file !== null && bodyFormData.append('file', body.file);
    return this.client.post(`/transport/updateTransportProcess?id=${id}`, bodyFormData, {headers: { "Content-Type": "multipart/form-data" }})
  }
}
