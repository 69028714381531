import React from 'react';
import { FormWrapper, FormInput } from './styles';
import { Formik } from 'formik';
import { Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

export const UploadZonesForm = () => {
    return (
        <Formik
        initialValues={{
          firstName: '',
        }}
        onSubmit={async (values) => {
          await new Promise((r) => setTimeout(r, 500));
          alert(JSON.stringify(values, null, 2));
          console.log(values);
        }}
      >
        {({ isValid, handleSubmit, submitForm, setFieldValue }) => (
        <FormWrapper>
          <h1>Subir nuevas tarifas por zona</h1>
          <p className="text-info">Subir archivo para actualizar parametros de cotización.</p>
          <FormInput>
            <input id="file" name="file" type="file" onChange={(event) => {
                setFieldValue("file", event.currentTarget.files[0]);
            }} />
            <UploadOutlined />
            Subir archivo
          </FormInput>
          <br />
          <Button type="button" onClick={submitForm}>Subir archivo de Zonas</Button>
        </FormWrapper>)}
      </Formik>
    )
};
