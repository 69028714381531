import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Upload, Button } from 'antd';
import { Formik } from 'formik';
import { MainForm } from './style';
import { ApiService } from '../../../services'
import { PageTitle } from '../../molecules/page-title';
import { FormikInput } from '../../molecules/formik-input-field';
import { AtomicButton } from '../../atoms/button';
import { getUserData } from '../../../store/auth/auth.selectors'

export const PurchaseUpdateProgressPage = () => {
    const history = useHistory();
    const { id } = useSelector(getUserData);
    const params = useParams();
    const createPurchase = async (body) => {
        const { data } = await ApiService.PurchaseService.updatePurchaseProgress(params.id, body);
        if(data.code === 200) history.push(`/purchase/${data.data.purchase.id}`)
    };
    return (
        <div>
            <div style={{ width: '100px' }}>
                <AtomicButton onClick={() => history.goBack()}>Atrás</AtomicButton>
            </div>
            <br />
            <PageTitle title={`Agregar progreso:`} />
            <Formik
                initialValues={{
                    updatedBy: id,
                    title: '',
                    status: 'Terminado',
                    description: '',
                    file: null,
                }}
                onSubmit={(data) => {
                    createPurchase(data);
                }}
                // validationSchema={yup.object().shape(schema)}
                >
                {({ handleSubmit, setFieldValue }) => (
                    <MainForm noValidate>
                        <FormikInput type="text" label="Titulo" name="title" />
                        <FormikInput type="text" label="Descripción" name="description" />
                        <label>Subir Pdf</label>
                        <Upload 
                        onChange={({ file, fileList }) => {
                            if (file.status !== 'uploading') {
                              console.log(file, fileList);
                              setFieldValue('file', fileList[0].originFileObj);
                            }
                          }}
                        >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                        <div>
                            <AtomicButton onClick={handleSubmit}>Agregar progreso</AtomicButton>
                        </div>
                    </MainForm>
                )}
            </Formik>
        </div>
    )
};
