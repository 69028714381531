import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Transition } from '../../organims/general-transition';
// import ReactWOW from 'react-wow';
import { AtomicCard } from '../../atoms/card';
import { Logo } from '../../atoms/logo';
import { AtomicButton } from '../../atoms/button';
import { TransportModule } from '../../organims/transport-archive';
import { PurchaseModule } from '../../organims/purchasing-archive-organism';
import { InventoryModule } from '../../organims/inventory-archive';
import { ProjectModule } from '../../organims/project-archive';
import { EmailListModule } from '../../organims/email-list-archive-organism';
import { StyledContainer } from './style';
import logo from '../../../assets/img/logo.png';
import { frases, branding } from '../../../constants/info';

const DefaultModule = () => (<Transition>
    <AtomicCard>
            <StyledContainer>
                <h1>{branding.slogan}</h1>
                <p>{branding.brandName}</p>
                <p>{frases[Math.floor(Math.random() * frases.length)]}</p>
            </StyledContainer>
            <StyledContainer>
                <Logo srcUrl={logo} alt={branding.logoAlt} heigth="44" />
            </StyledContainer>
        </AtomicCard>
</Transition>);


const Tables = {
    TransportModule,
    PurchaseModule,
    InventoryModule,
    ProjectModule,
    EmailListModule,
    DefaultModule
}

export const GeneralUserMenu = ({ funcionalidades }) => {
    const { t } = useTranslation();
    const [tableComp, setTableComp] = useState('DefaultModule')
    const TableComponent = Tables[tableComp];
    return (
        <>
        <AtomicCard>
            <StyledContainer>
                <h1>{branding.slogan}</h1>
                <p>{branding.brandName}</p>
                <p>{frases[Math.floor(Math.random() * frases.length)]}</p>
            </StyledContainer>
            <StyledContainer style={{ minWidth: '250px' }}>
                {funcionalidades.map((func, index) => <AtomicButton key={index} onClick={() => func.componentName ? setTableComp(func.componentName) : setTableComp('DefaultModule')}>{t(func.label)}</AtomicButton>)}
            </StyledContainer>
            <StyledContainer>
                <Logo srcUrl={logo} alt={branding.logoAlt} heigth="44" />
            </StyledContainer>
        </AtomicCard>
        <TableComponent />
        </>
    )
};