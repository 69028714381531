import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import { Formik } from 'formik';
import { Upload, Button } from 'antd';
import * as yup from 'yup';
import { MainForm } from './style';
import { ApiService } from '../../../services'
import { PageTitle } from '../../molecules/page-title';
import { FormikInput } from '../../molecules/formik-input-field';
import { AtomicButton } from '../../atoms/button';

export const StockCreatePage = () => {
    const history = useHistory();
    const [uplaodInBulk, setUplaodInBulk] = useState(false);
    return (
        <div>
            <div style={{ width: '100px' }}>
                <AtomicButton onClick={() => history.goBack()}>Atrás</AtomicButton>
            </div>
            <br />
            <PageTitle title={`Creación de referencia:  `} />
            <div style={{ width: '100px' }}>
                <AtomicButton onClick={() => setUplaodInBulk(!uplaodInBulk)}>{!uplaodInBulk ? 'Subir x CSV' : 'Subir x formulario'}</AtomicButton>
            </div>
            {!uplaodInBulk ? (<Formik
                initialValues={{
                    ref: '',
                    name: '',
                    total: 0,
                    reservado: 0,
                    disponible: 0,
                    tipo: '',
                    collecion: '', 
                    soldaduraTotal: 0,
                    soldaduraReservada: 0,
                    soldaduraDisponible: 0,
                    refSoldadura: null,
                    floorImg: 'https://profesional.tarkett.es/media/img/M/THH_21010641_001.jpg',
                    unidad: '',
                    presentacion: '',
                    rendimiento: 0,
                    peso: 0,
                    value: 0,
                    obs: '',
                    stock: [],
                    movimientos: []
                }}
                onSubmit={async (body) => {
                    try {
                        const { data } = await ApiService.StockService.createStockRef(body);
                        console.log(data);
                        if(data.code === 200) history.push(`/stock-ref/${data.data.newStock.id}`)
                    } catch (err){
                        console.log(err.response);
                    }
                }}
                validationSchema={yup.object().shape({
                    ref: yup.string().required('Campo obligatorio'),
                    name: yup.string().required('Campo obligatorio'),
                    total: yup.number().required('Campo obligatorio'),
                    reservado: yup.number().required('Campo obligatorio'),
                    disponible: yup.number().required('Campo obligatorio'),
                    tipo: yup.string().required('Campo obligatorio'),
                    collecion: yup.string().required('Campo obligatorio'),
                    soldaduraTotal: yup.number().required('Campo obligatorio'),
                    soldaduraReservada: yup.number().required('Campo obligatorio'),
                    soldaduraDisponible: yup.number().required('Campo obligatorio'),
                    refSoldadura: yup.string().nullable(),
                    floorImg: yup.string().required('Campo obligatorio'),
                    unidad: yup.string().required('Campo obligatorio'),
                    presentacion: yup.string().required('Campo obligatorio'),
                    rendimiento: yup.number().required('Campo obligatorio'),
                    peso: yup.number().required('Campo obligatorio'),
                    value: yup.number().required('Campo obligatorio'),
                    obs: yup.string().nullable(),
                    stock: yup.array(),
                    movimientos: yup.array(),
                })}
                >
                {({ handleSubmit, values, errors }) => (
                    <MainForm onSubmit={handleSubmit} noValidate>
                        {console.log(errors)}
                        <FormikInput type="text" label="Referencia" name="ref" />
                        <FormikInput type="text" label="Nombre" name="name" />
                        <FormikInput type="number" label="Total" name="total" />
                        <FormikInput type="number" label="Reservado" name="reservado" />
                        <FormikInput type="number" label="Disponible" name="disponible" />
                        <FormikInput type="text" label="Tipo" name="tipo" />
                        <FormikInput type="text" label="Collección" name="collecion" />
                        <FormikInput type="number" label="Soldadura total (Si aplica)" name="soldaduraTotal" />
                        <FormikInput type="number" label="Soldadura reservado (Si aplica)" name="soldaduraReservada" />
                        <FormikInput type="number" label="Soldadura disponible (Si aplica)" name="soldaduraDisponible" />
                        <FormikInput type="text" label="Ref de soldadura (Si aplica)" name="refSoldadura" />
                        <FormikInput type="text" label="Link de img" name="floorImg" />
                        <FormikInput type="text" label="Unidad de medida" name="unidad" />
                        <FormikInput type="text" label="Presentación de referencia" name="presentacion" />
                        <FormikInput type="number" label="Rendimiento x unidad" name="rendimiento" />
                        <FormikInput type="number" label="Peso x unidad" name="peso" />
                        <FormikInput type="number" label="Valor x unidad" name="value" />
                        <FormikInput type="text" label="Observaciones de ref" name="obs" />
                        <div>
                            <AtomicButton 
                                type="button" 
                                onClick={handleSubmit}
                            >
                                Crear referencia
                            </AtomicButton>
                        </div>
                    </MainForm>
                )}
            </Formik>):
            ( <Formik
                initialValues={{
                    file: null,
                }}
                onSubmit={async (body) => {
                    try {
                        const { data } = await ApiService.StockService.createStockRefWithCSV(body);
                        console.log(data);
                        if(data.code === 200) history.push(`/stock`)
                    } catch (err){
                        console.log(err.response);
                    }
                }}
                // validationSchema={yup.object().shape(schema)}
                >
                {({ handleSubmit, setFieldValue }) => (
                    <MainForm noValidate>
                        <label>Subir CSV</label>
                        <Upload 
                        onChange={({ file, fileList }) => {
                            if (file.status !== 'uploading') {
                              console.log(file, fileList);
                              setFieldValue('file', fileList[0].originFileObj);
                            }
                          }}
                        >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                        <div>
                            <AtomicButton onClick={handleSubmit}>Agregar CSV</AtomicButton>
                        </div>
                    </MainForm>
                )}
            </Formik>)}
        </div>
    )
};
