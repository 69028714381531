import styled from 'styled-components';

export const PdfMainDiv = styled.div`
    .pdfDocument{
    }
    .react-pdf__Page__canvas{
        display: block;
        user-select: none;
        width: -webkit-fill-available;
        height: auto;
    }
`;