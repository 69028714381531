import styled from 'styled-components';
import { Spin } from 'antd';
export const StyledSpiner = styled(Spin)`
 & > .ant-spin {
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    display: flex;
    padding: inherit;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin: 50px auto !important;
}
`;