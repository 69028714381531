import React, { useEffect, useState } from 'react'
import { Tag } from 'antd';
import { ApiService } from '../../../services';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Transition } from '../../organims/general-transition';
import { PageTitle } from '../../molecules/page-title';
import { TableCustom } from '../../organims/table';
import { AtomicButton } from '../../atoms/button';
import { LoaderAtom } from '../../atoms/loading';

export const TransportModule = () => {
  const [transports, setTransports] = useState(null);
    const { t } = useTranslation();
    const history = useHistory();
    const getPurchases = async () => {
      const { data } = await ApiService.TransportService.getTransports();
      console.log(data);
      setTransports(data.data.transports);
    };
    useEffect(() => getPurchases(), [])
    const columns = [
      /* {
        title: 'Company',
        dataIndex: 'company',
        key: 'company'
      }, */
      {
        title: 'Estado',
        dataIndex: 'status',
        render: (text, record) => (
          <Tag color='blue'>
            {record.status.toUpperCase()}
          </Tag>
        ),
      },
      /* {
        title: 'Tipo',
        dataIndex: 'type',
        key: 'type'
      },
      {
        title: 'Origen',
        dataIndex: 'origin',
        key: 'origin'
      },
      {
        title: 'Destino',
        dataIndex: 'destination',
        key: 'destination'
      }, */
      /* {
        title: 'ITR',
        dataIndex: 'itr',
        key: 'itr',
        render: (text) => text ? 'Incluido' : 'No incluido'
      },
      {
        title: 'Devolución',
        dataIndex: 'return2port',
        key: 'return2port',
        render: (text) => text ? 'Incluido' : 'No incluido'
      }, 
      {
        title: 'Cargue',
        dataIndex: 'load',
        key: 'load',
        render: (text) => text ? 'Incluido' : 'No incluido'
      },
      {
        title: 'Descargue',
        dataIndex: 'unload',
        key: 'unload',
        render: (text) => text ? 'Incluido' : 'No incluido'
      },
      {
        title: 'Peso (Kg)',
        dataIndex: 'weight',
        key: 'weight'
      },
      {
        title: 'Observaciones',
        dataIndex: 'obs',
        key: 'obs'
      },
      {
        title: 'Fecha',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (text) => new Date(text).toLocaleDateString()
      },*/
      {
        title: 'Valor',
        dataIndex: 'value',
        key: 'value'
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <AtomicButton onClick={() => history.push(`/transport/${record.id}`)}>+ Info</AtomicButton>
        ),
        fixed: 'right'
      }
    ];
    if(!transports) return <LoaderAtom />;
    return (
        <Transition>
            <PageTitle title={t('Transportes')} />
          <AtomicButton onClick={() => history.push(`/nuevo-transport`)}>Nuevo transporte</AtomicButton>
            <TableCustom
                columns={columns} 
                data={transports} 
                searchableTable={true}
                searchablePlaceholder="Transporte"  
                serachableObjKey='movement_consec'
            />
        </Transition>
    )
};