import React, { useState } from 'react';
import { WrapperShipments } from './styled';
import { PageTitle } from '../../molecules/page-title';
import { Steps, Button, Row, Col, Avatar, Image } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
const { Step } = Steps;

const CreaatedComponent = (Order) => (
  <div>
    <p>Project name: <strong>{Order.name}</strong></p>
    <p>Project company: <strong>{Order.company}</strong></p>
    <p>Project company: <strong>{Order.company}</strong></p>
    <p>Project Type: <strong>{Order.type}</strong></p>
    <p>Wrap Type: <strong>{Order.wrapType}</strong></p>
    <p>Prject Supervisor: <strong>{Order.supervisor}</strong></p>
    <p>Project Designer: <strong>{Order.designer}</strong></p>
    <p>Project Installer: <strong>{Order.installer}</strong></p>
    <p>Observation: <strong>{Order.observations}</strong></p>
  </div>
);

const AvatarComponent = () => (
  <div style={{
    padding: '1em',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignContent: 'center'
  }}>
    <Avatar src="https://i1.sndcdn.com/avatars-000237796344-qar8t9-t500x500.jpg" />
    <p>Felipe has been selected as the projects designer.</p>
  </div>
);

const DesignApprovedComponent = () => (
  <div style={{
    padding: '1em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignContent: 'center'
  }}>
    <Image
      width={200}
      src="https://carwrapsolutions.com/images/car-wraps-advertising-fort-lauderdale-florida.jpg"
    />
    <br />
    <Image
      width={200}
      src="https://carwrapsolutions.com/images/car-wraps-fort-lauderdale-davie-florida.jpg"
    />
    <br />
    <Image
      width={200}
      src="https://carwrapsolutions.com/images/Ford-Transit-Connect-Van-Vehicle-Wraps-Fort-Lauderdale-Miami-Palm-Beach-Florida.jpg"
    />
  </div>
);

const DesignDownloadComponent = () => (
  <div style={{
    padding: '1em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center'
  }}>
    <Image
      width={200}
      src="https://carwrapsolutions.com/images/car-wraps-advertising-fort-lauderdale-florida.jpg"
    />
    <br />
    <Button onClik={() => alert('Download Files')}> Download Design Files</Button>
  </div>
);

const MaterialComponent = (_, completeStep) => (
  <div style={{
    padding: '1em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center'
  }}>
    <Image
      width={200}
      src="https://carwrapsolutions.com/images/car-wraps-advertising-fort-lauderdale-florida.jpg"
    />
    <br />
    <Button onClik={() => completeStep()}>Material listo!</Button>
  </div>
);

const Avatar2Component = () => (
  <div style={{
    padding: '1em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignContent: 'center'
  }}>
    <div style={{
      padding: '1em',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignContent: 'center'
    }}>
      <Avatar src="https://deadline.com/wp-content/uploads/2020/11/Stephen-Lang-Headshot-Matt-Sayles-e1605093774374.jpg" />
      <p>Salvatore has been selected as the projects installer.</p>
    </div>
    <div style={{
      padding: '1em',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignContent: 'center'
    }}>
      <Avatar src="https://images.ctfassets.net/juauvlea4rbf/7gaBuvSxDqsc44uUgAk66g/7f16f20f38a1c0c6d2711db04b22658c/author-claire_2x-50.jpg?w=1200&h=1200&fl=progressive&q=50&fm=jpg" />
      <p>Claudia has been selected as the projects installer.</p>
    </div>
  </div>
);

const DropOff = () => (
  <div style={{
    padding: '1em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center'
  }}>
    <CheckCircleFilled style={{ color: 'green', fontSize: '32px' }} />
    <br />
    <p>Car has been dropped off. Ready to install on the 30/09/2021</p>
  </div>
);

const DesignApprovedComponent2 = () => (
  <div style={{
    padding: '1em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignContent: 'center'
  }}>
    <Image
      width={200}
      src="https://carwrapsolutions.com/images/car-wraps-advertising-fort-lauderdale-florida.jpg"
    />
    <br />
    <Image
      width={200}
      src="https://carwrapsolutions.com/images/car-wraps-fort-lauderdale-davie-florida.jpg"
    />
    <br />
    <Image
      width={200}
      src="https://carwrapsolutions.com/images/Ford-Transit-Connect-Van-Vehicle-Wraps-Fort-Lauderdale-Miami-Palm-Beach-Florida.jpg"
    />
    <br />
    <CheckCircleFilled style={{ color: 'green', fontSize: '32px' }} />
    <br />
    <p>Car has been installed and approved by supervisor. Ready to install on the 10/2/2021</p>
  </div>
);

const PickUp = () => (
  <div style={{
    padding: '1em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center'
  }}>
    <CheckCircleFilled style={{ color: 'green', fontSize: '32px' }} />
    <br />
    <p>Car has been picked-up. Ready to install on the 5/10/2021</p>
  </div>
);


const Finished = () => (
  <div style={{
    padding: '1em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center'
  }}>
    <CheckCircleFilled style={{ color: 'green', fontSize: '32px' }} />
    <br />
    <p>Project finished succesfully 8/10/2021</p>
  </div>
);

export const ShipmentsDetail = () => {
  const [step, setStep] = useState(0);
  const [completed, setCompleted] = useState(0);
  const prevStep = () => {
    if (step !== 0 || completed !== 0) {
      setStep(step - 1);
      setCompleted(completed -1);
    }
  }
  const completeStep = () => {
    if (step !== 9 || completed !== 9) {
      setStep(step + 1);
      setCompleted(completed + 1)
  }
  }
  const onChange = changeStep => {
    if(changeStep <= completed) setStep(changeStep);
  }
  return (
    <WrapperShipments>
      <PageTitle  title={`Project Name: ${WrapData.name}-${WrapData.company}`} />
      <p>Observations: {WrapData.observations}</p>
      <Row gutter={16}>
        <Col flex="1 0 50%">
          <Steps 
            direction="vertical" 
            current={step}
            onChange={onChange}
          >
            {projectPrgress.map((progress, index) => <Step 
              key={progress.title}
              title={progress.title}
              description={index >= completed ? progress.description : progress.completed}
              status={index <= completed ? "finish" : "wait"}
            />)}
          </Steps>
        </Col>
        <Col flex="1 0 50%">
        <div style={{
          display: 'flex',
          flexDirection: 'column'
        }}>
          <h1>{projectPrgress[step].title}</h1>
          <p>{projectPrgress[step].completed}</p>
          {projectPrgress[step].info(WrapData, completeStep)}
          <div style={{
            display: 'flex',
            flexDirection: 'row'
          }}>
            <Button onClick={prevStep}>Antes</Button>
            <Button onClick={completeStep}>Siguiente</Button>
          </div>
        </div>
        </Col>
      </Row>
    </WrapperShipments>
  );
};

const WrapData = {
  name: 'Food Truck Wrap',
  company: 'Rocked & Loaded',
  type: 'Car Wrap',
  wrapType: 'Graphics',
  supervisor: 'Santiago',
  designer: 'Santiago',
  installer: 'Lin',
  status: 'Initiated',
  observations: 'No initial observations.'
}

const projectPrgress = [
  {
    title: 'Order Created',
    description: 'This project is created.',
    completed: 'This project is created.',
    info: CreaatedComponent
  },
  {
    title: "Assign Designer",
    description: "No designer is assigned to the project.",
    completed: 'Felipe is assigned to design this project.',
    info: AvatarComponent
  },
  {
    title: "Aprove Design",
    description: "Design not approved.",
    completed: 'The client has approved the design',
    info: DesignApprovedComponent
  },
  {
    title: "Design Files",
    description: "The design files are  not ready.",
    completed: 'The design files are ready.',
    info: DesignDownloadComponent
  },
  {
    title: "Material",
    description: "Material is not ready for installation.",
    completed: 'Material is ready for installation.',
    info: MaterialComponent
  },
  {
    title: "Assign Installers",
    description: "No installers assigned.",
    completed: 'Salvatore & Claudia have been assigned',
    info: Avatar2Component
  },
  {
    title: "Car Drop Off",
    description: "The Car has been droped off.",
    completed: 'The car has been droped off.',
    info: DropOff
  },
  {
    title: "Installation",
    description: "Not installed yet.",
    completed: 'Installed',
    info: DesignApprovedComponent2
  },
  {
    title: "Ready for pick-up",
    description: "Prject not ready for pick-up.",
    completed: 'Pick-up ready',
    info: PickUp
  },
  {
    title: "Project finished",
    description: "Project has not ended.",
    completed: 'Project finished',
    info: Finished
  },
  
];


