import React from 'react';
import { Spring, animated } from 'react-spring';

export const Transition = ({ children}) => {
    return (
    <Spring
        // loop
        // from={{ opacity: 0, color: 'red' }}
        // to={[
        // { opacity: 1, color: '#ffaaee' },
        // // { opacity: 0, color: 'rgb(14,26,19)' },
        // ]}
        
        from={{ x: -10 }}
        to={{ x: 0 }}
    >
        {styles => (
            <animated.div style={styles}>{children}</animated.div>
        )}
    </Spring>
    )
};

