import React from 'react';
import { Modal } from 'antd';
// import { ContainerModal } from './styles';

export const ModalDialog = ({
    children,
    size,
    centered,
    animation,
    scrollable,
    contentClassName,
    dialogClassName,
    onToggle,
    openShow,
    title,
    typeModal
}) => {
    return (
    <Modal title={title} visible={openShow} onOk={onToggle} onCancel={onToggle}>
        {children}
    </Modal>
    )
}
