import { useState } from 'react';
import { Table, Space } from 'antd';
import { CheckCircleTwoTone, CloseCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { getUserData } from '../../../store/auth/auth.selectors';
import { ApiService } from '../../../services';
import { useModal } from '../../../utils/useModal';
import { AtomicButton } from '../../atoms/button';
import { useHistory } from 'react-router-dom';
// import { StyledTable, StyledRefMainRow, StyledRefMainRowInnerImg, StyledRefMainRowInnerInfo } from './style';



export const StockInnerTableOrganism = ({ stock }) => {
  const history = useHistory();
  const [show, onToggle, ContainerModalDialog] = useModal(false);
  const [message, setMessage] = useState(null);
  const user = useSelector(getUserData);
  const handleConfirm = async (num) => { 
    if(num !== "Not Found"){
        try {
            const res = await ApiService.StockService.updateVerifiedItemById(num);
            console.log(res);
            setMessage(res);
            onToggle()
            window.location.reload();
        } catch (err) {
            onToggle()
            setMessage(err.response);
        }
    }
}
    const columns = [
      { title: 'Numero de rollo', dataIndex: 'num', key: 'num' },
      { title: 'Bach', dataIndex: 'bach', key: 'bach' },
      {
        title: 'Medidas',
        key: 'width',
        render: (_, row ) => (
          <span>
            {`${row.width}x${row.length}`}
          </span>
        ),
      },
      { title: 'Total', dataIndex: 'total', key: 'total' },
      { title: 'Reservado', dataIndex: 'reservado', key: 'reservado' },
      { title: 'Disponible', dataIndex: 'disponible', key: 'disponible' },
      { title: 'Ubicación', dataIndex: 'ubicacion', key: 'ubicacion' },
      { title: 'Obs', dataIndex: 'obs', key: 'obs' },
      {
        title: 'Verificado',
        dataIndex: 'verified',
        key: 'verified',
        render: (_, row ) => (
          <Space>{row.verified ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : user.gerencia? <AtomicButton onClick={() => handleConfirm(row.num)}>Verificar</AtomicButton> : <CloseCircleOutlined twoToneColor="#52c41a" />}</Space>
        ),
      },
    ];
    if (user.gerencia){
      columns.push({ title: 'Total', dataIndex: 'total', key: 'total',
      render: (_, row ) => (
        <Space><AtomicButton onClick={() => history.push('/movement-create/Ajuste', row)}>Ajustar</AtomicButton></Space>
      ), })
    }

    return (<>
    
    <Table 
      rowKey="id" columns={columns} dataSource={stock} /* pagination={false} */ />
    {show && (<ContainerModalDialog
                title='Información'
            >
                {message?.data?.message}
            </ContainerModalDialog>
    )}
    </>)
};

  