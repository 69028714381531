import { Table } from 'antd';
import { useHistory } from 'react-router-dom';
import { StyledTable, StyledRefMainRow, StyledRefMainRowInnerImg, StyledRefMainRowInnerInfo } from './style';
import { inventario } from '../../../constants/info';

export const  StockTableOrganism = ({ inventory }) => {
  const history = useHistory()
  const expandedRowRender = ({ stock }) => {
    const columns = [
      { title: 'Numero de rollo', dataIndex: 'num', key: 'num' },
      { title: 'Bach', dataIndex: 'bach', key: 'bach' },
      {
        title: 'Medidas',
        key: 'width',
        render: (_, row ) => (
          <span>
            {`${row.width}x${row.length}`}
          </span>
        ),
      },
      { title: 'Total', dataIndex: 'total', key: 'total' },
      { title: 'Reservado', dataIndex: 'reservado', key: 'reservado' },
      { title: 'Disponible', dataIndex: 'disponible', key: 'disponible' },
    ];
    return <Table columns={columns} dataSource={stock} pagination={false} />;
  };

  const columns = [
    { title: 'Ref.', dataIndex: 'ref', key: 'ref', render: (text, row, index) => {
        return (
            <StyledRefMainRow>
                <StyledRefMainRowInnerImg img={row.floorImg}>
                </StyledRefMainRowInnerImg>
                <StyledRefMainRowInnerInfo>
                    <button onClick={() => history.push(`/stock-ref/${row.id || row.key}`)}>{text}</button>
                    <p>{row.name}</p>
                    <p>{`${row.tipo}-${row.collecion}`}</p>
                    <p>{`Total: ${row.total}, Reservado: ${row.reservado} Disponible: ${row.disponible}`}</p>
                    {row.refSoldadura && (<p>{`Soldadura: ${row.refSoldadura}`}</p>)}
                    {row.refSoldadura && (<p>{`Sold.Total: ${row.soldaduraTotal}, Reservado: ${row.soldaduraReservada} Disponible: ${row.soldaduraDisponible}`}</p>)}
                </StyledRefMainRowInnerInfo>
            </StyledRefMainRow>
        )
    } },

  ];

  return (
    <StyledTable
      className="components-table-demo-nested"
      rowKey="id"
      columns={columns}
      expandable={{ expandedRowRender }}
      dataSource={inventory || inventario}
    />
  );
}