import React, { useRef, forwardRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { AtomicButton } from '../../atoms/button';
import { Logo } from '../../atoms/logo';
import { MainPrintPage } from './style';
import logo from '../../../assets/img/logo.png';

const ComponentToPrint = forwardRef((props, ref) => (
    <MainPrintPage ref={ref}  className="print-container" style={{ margin: "0", padding: "0" }}>
        <div>
            <Logo srcUrl={logo} alt="logo-bylin" heigth="44" />
        </div>
      {props.children}
    </MainPrintPage>
));

export const PrintOrganism = ({ children }) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div>
      <ComponentToPrint ref={componentRef} info={{ child: 'child' }}>{children}</ComponentToPrint>
      <AtomicButton onClick={handlePrint}>Imprimir</AtomicButton>
    </div>
  );
};