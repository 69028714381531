import React from 'react';
import { PageTitle } from '../../molecules/page-title';
import { MainHome } from './styled';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux'
import { MainCardIndicator } from '../../molecules/main-card-indicator';
import { GeneralUserMenu } from '../../organims/general-user-menu';
// import GeneralHomeFunctions from '../../organims/general-home-functions';
// import { MainTable } from '../../molecules/main-table';
import { funcionalidades } from '../../../constants/funcionalidades';
// the hook
import { useTranslation } from 'react-i18next';
import { getUserData } from '../../../store/auth/auth.selectors'

export const Home = () => {
  const { t } = useTranslation();
  const { firstName } = useSelector(getUserData);
  const indicators = [
    {
      title: t('Costos'),
      content: '15MM-Q',
      crecimiento: -2,
      acendente: false,
      icon: 'ArrowDownOutlined'
    },
    {
      title: 'Ingresos',
      content: '30MM-Q',
      crecimiento: -2,
      acendente: true,
      icon: 'ArrowUpOutlined'
    },
    {
      title: 'Cartera',
      content: '13MM-Q',
      crecimiento: 2,
      acendente: false,
      icon: 'WalletOutlined'
    },
    {
      title: 'Solicitudes',
      content: '33-Q',
      crecimiento: -2,
      acendente: true,
      icon: 'ProfileOutlined'
    },
    {
      title: 'Solicitudes Activas',
      content: '13-Q',
      crecimiento: 2,
      acendente: true,
      icon: 'AreaChartOutlined'
    },
    {
      title: 'Solicitudes Terminadas',
      content: '13-Q',
      crecimiento: -2,
      acendente: true,
      icon: 'RiseOutlined'
    }
  ];

   return (
  <MainHome>
    <PageTitle title={`${t('Inicio')} ${firstName}`} />
    <Row gutter={16}>
      {indicators.map(indicator => (
        <Col flex="1 0 30%" key={indicator.title}>
          <MainCardIndicator
            bordered={false}
            indicator={indicator}
          />
      </Col>
      ))}
    </Row>
    <PageTitle title={t('UserFuncs')} />
    <GeneralUserMenu funcionalidades={funcionalidades} />
    {/* <PageTitle title={t('Menu')} />
    <GeneralHomeFunctions page="gerencia" funcionalidades={funcionalidades} /> */}
  </MainHome>
)};
