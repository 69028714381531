import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ModalDialog } from '../components/atoms/modal-dialog';


export const useModal = (initialvalue) => {
    const [show, setShow] = useState(initialvalue);

    const onToggle = () => {
        setShow(!show);
    }

    const ContainerModal = ({
        dialogClassName,
        contentClassName,
        size,
        typeModal,
        scrollable,
        centered,
        animation,
        children,
        title
    }) => {
        return (
            <ModalDialog
                typeModal={typeModal}
                size={size}
                openShow={show}
                onToggle={onToggle}
                centered={centered}
                animation={animation}
                scrollable={scrollable}
                contentClassName={contentClassName}
                dialogClassName={dialogClassName}
                title={title}
            >
                {children}
            </ModalDialog>
        )
    }
    ContainerModal.propTypes = {
        dialogClassName: PropTypes.string,
        contentClassName: PropTypes.string,
        size: PropTypes.oneOf(['sm' | 'lg' | 'xl']),
        typeModal: PropTypes.string.isRequired,
        show: PropTypes.bool.isRequired,
        scrollable: PropTypes.bool,
        centered: PropTypes.bool,
        animation: PropTypes.bool,
        onToggle: PropTypes.func.isRequired,
        children: PropTypes.node
    }
    return [show, onToggle, ContainerModal]
}