import React, { useEffect, useState } from 'react';
import { ApiService } from '../../../services';
import { useHistory, useParams } from 'react-router-dom';
import { Steps } from 'antd';
import { PageTitle } from '../../molecules/page-title';
import { AtomicButton } from '../../atoms/button';
import { LoaderAtom } from '../../atoms/loading';
import { Base64PreviewMolecule } from '../../molecules/pdf-previewer-molecule';
const { Step } = Steps;

export const TransportDetailPage = () => {
    const [transport, setTransport] = useState(null)
    const [current, setCurrent] = useState(transport?.progress.length);
    const history = useHistory();
    const params = useParams();
    const handleChange = (index) => {
        setCurrent(index);
    };
    const getPurchases = async () => {
        const { data } = await ApiService.TransportService.getTransportById(params.id);
        console.log(data);
        setTransport(data.data.transport);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
      useEffect(() => getPurchases(), [])
    if(!transport) return <LoaderAtom />;
    return (
        <div>
            <div style={{ width: '100px' }}>
                <AtomicButton onClick={() => history.goBack()}>Atrás</AtomicButton>
            </div>
            <br />
            <PageTitle title={`Consecutivo de transporte: ${transport.type} ${transport.movement_consec} `} />
            <h3>{`Status: ${transport.status}`}</h3>
            <h3>{`Trayecto: ${transport.origin} - ${transport.destination}`}</h3>
            <h3>{`Fecha: ${new Date(transport.createdAt).toLocaleDateString()}`}</h3>
            <h3>{`Transportadora: ${transport.company}`}</h3>
            <h3>{`ITR: ${transport.itr ? 'Incluido' : 'N/A'}`}</h3>
            <h3>{`Cargue: ${transport.load ? 'Incluido' : 'N/A'}`}</h3>
            <h3>{`Descargue: ${transport.unload ? 'Incluido' : 'N/A'}`}</h3>
            <h3>{`Peso: ${transport.weight}`}</h3>
            <h3>{`Valor: ${transport.value}`}</h3>
            <h3>{`Retorna a origen: ${transport.return2port ? 'Si' : 'No'}`}</h3>
            <h3>Observaciones:</h3>
            <h3>{transport.obs || 'Sin observaciones...'}</h3>
            <div>
                <AtomicButton onClick={() => history.push(`/transport-update/${transport.id}`, transport)}>Editar</AtomicButton>
                <AtomicButton onClick={() => history.push(`/transport-update-progress/${transport.id}`, transport)}>Agregar progreso</AtomicButton>
            </div>
            <PageTitle title="Progreso de transporte" />
            {transport.progress.length !== 0 && <Steps 
                direction="vertical"
                current={current}
                onChange={handleChange}
                navigation
            >   
                {transport.progress.map((step) => (
                    <Step
                        title={step.title}
                        subTitle={new Date(step.subTitle).toLocaleDateString()}
                        status={'finish'}
                        description={step.description}
                    />
                ))}
            </Steps>}
            {transport.progress[current]?.file && <Base64PreviewMolecule file={transport.progress[current]?.file} />}
        </div>
    )
};
