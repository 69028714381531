import React, { useState } from 'react';
// import { ApiService } from '../../../services';
import { useSelector } from 'react-redux';
import { useHistory/* , useParams */ } from 'react-router-dom';
import { Steps } from 'antd';
import { PageTitle } from '../../molecules/page-title';
import { AtomicButton } from '../../atoms/button';
import { LoaderAtom } from '../../atoms/loading';
import { MovementTableOrganism } from '../../organims/movement-table-organism';
import { ProjectEndorganism } from '../../organims/project-final';
import { Base64PreviewMolecule } from '../../molecules/pdf-previewer-molecule';
import { getUserData } from '../../../store/auth/auth.selectors'
import { Tabs } from 'antd';

const { TabPane } = Tabs;
const { Step } = Steps;

export const ProjectDetailPage = () => {
    const { gerencia } = useSelector(getUserData);
    const [project/* , setproject */] = useState(theTestProject);
    const [current, setCurrent] = useState(project?.progress.length);
    const history = useHistory();
    // const params = useParams();
    const handleChange = (index) => {
        setCurrent(index);
    };
    function callback(key) {
        console.log(key);
      }
    // const getPurchases = async () => {
    //     const { data } = await ApiService.projectService.getprojectById(params.id);
    //     console.log(data);
    //     setproject(data.data.project);
    //   };
      // eslint-disable-next-line react-hooks/exhaustive-deps
      // useEffect(() => getPurchases(), [])
    if(!project) return <LoaderAtom />;
    const movementSummry = project.movimientos.map(pro => {
        const n = pro.stock.map(s => ({...s, type: pro.type, price: 80000}))
        return n;
    });
    return (
        <div>
            <div style={{ width: '100px' }}>
                <AtomicButton onClick={() => history.goBack()}>Atrás</AtomicButton>
            </div>
            <br />
            <PageTitle title={`Consecutivo de proyecto: ${project.project_consec}-${project.name}-${project.type} `} />
            <h3>{`Status: ${project.status}`}</h3>
            <h3>{`Fecha: ${new Date(project.createdAt).toLocaleDateString()}`}</h3>
            <PageTitle title="Cliente" />
            <p>{`Cliente: ${project.client.compnayName}`}</p>
            <p>{`Contacto: ${project.client.firstName} ${project.client.lastName}, ${project.client.title}`}</p>
            <p>{`Cel: ${project.client.phone}, Email: ${project.client.email}`}</p>
            {gerencia && (
                <>
                <PageTitle title="Valor" />
                <p>{`Valor: ${project.value}COP`}</p>
                </>
            )}
            <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="Progreso de proyecto" key="1">
                    <PageTitle title="Progreso de proyecto" />
                    {project.progress.length !== 0 && <Steps 
                        direction="vertical"
                        current={current}
                        onChange={handleChange}
                        navigation
                    >   
                        {project.progress.map((step) => (
                            <Step
                                title={step.title}
                                subTitle={new Date(step.subTitle).toLocaleDateString()}
                                status={'finish'}
                                description={step.description}
                            />
                        ))}
                    </Steps>}
                    {project.progress[current]?.file && <Base64PreviewMolecule file={project.progress[current]?.file} />}
                </TabPane>
                <TabPane tab="Resumen de proyecto" key="2">
                    <PageTitle title="Resumen de proyecto" />
                    <ProjectEndorganism items={movementSummry.flat()} />
                </TabPane>
                <TabPane tab="Movimiento de Proyecto" key="3">    
                    <PageTitle title="Movimiento de Proyecto" />
                    <MovementTableOrganism inventory={[...new Map(project.movimientos.map(item => [item.id, item])).values()]} />
                </TabPane>
                <TabPane tab="Info General de Proyecto" key="4">    
                    <PageTitle title="Info General" />
                    <h3>Comercial</h3>
                    <p>{`Contacto: ${project.comercial.firstName} ${project.comercial.lastName}, ${project.comercial.title}`}</p>
                    <p>{`Cel: ${project.comercial.phone}, Email: ${project.comercial.email}`}</p>
                    <h3>Logística</h3>
                    <p>{`Contacto: ${project.logistics.firstName} ${project.logistics.lastName}, ${project.logistics.title}`}</p>
                    <p>{`Cel: ${project.logistics.phone}, Email: ${project.logistics.email}`}</p>
                    <h3>Técnico</h3>
                    <p>{`Contacto: ${project.supervisor.firstName} ${project.supervisor.lastName}, ${project.supervisor.title}`}</p>
                    <p>{`Cel: ${project.supervisor.phone}, Email: ${project.supervisor.email}`}</p>
                    <h3>Instaladores</h3>
                    {project.instaladores.length === 0 ? <p>No hay instaladores asignados.</p> : project.instaladores.map((instalador) => (
                        <>
                            <h5>Instalador:</h5>
                            <p>{`Contacto: ${instalador.firstName} ${instalador.lastName}, ${instalador.title}`}</p>
                            <p>{`Cel: ${instalador.phone}, Email: ${instalador.email}`}</p>
                        </>
                    ))}
                    <h3>Observaciones:</h3>
                    <h3>{project.obs || 'Sin observaciones...'}</h3>
                </TabPane>
                <TabPane tab="Gestionar Proyecto" key="5">    
                    <PageTitle title="Gestionar Proyecto" />
                    <div>
                        <AtomicButton onClick={() => history.push(`/project-update/${project.id}`, project)}>Editar</AtomicButton>
                        <AtomicButton onClick={() => history.push(`/project-update/${project.id}`, project)}>Reserva</AtomicButton>
                        <AtomicButton onClick={() => history.push(`/project-update/${project.id}`, project)}>Salida</AtomicButton>
                        <AtomicButton onClick={() => history.push(`/project-update/${project.id}`, project)}>Entrada</AtomicButton>
                        <AtomicButton onClick={() => history.push(`/project-update/${project.id}`, project)}>Liquidación</AtomicButton>
                        <AtomicButton onClick={() => history.push(`/project-update/${project.id}`, project)}>Facturación</AtomicButton>
                        <AtomicButton onClick={() => history.push(`/project-update-progress/${project.id}`, project)}>Agregar progreso</AtomicButton>
                    </div>
                </TabPane>
            </Tabs>
        </div>
    )
};


const theTestProject = {        
    id: '1',
    project_consec: 210001,
    status: 'Iniciado',
    name: 'Compensar',
    type: 'Venta/Instalación',
    createdAt: new Date().toISOString(),
    client: {
        compnayName: "Bylin SAS",
        phone: "3214912185",
        title: 'Jefe de Logistica',
        email: 'pandalabco@gmail.com',
        firstName: 'Mattias',
        lastName: 'Bylin',
        createdAt: '2022-01-26T16:17:32.591Z',
        id: '61f1741ca4b6200f4773f8f4'
    },
    comercial: {
        compnayName: "Bylin SAS",
        phone: "3214912185",
        title: 'Jefe de Logistica',
        email: 'pandalabco@gmail.com',
        firstName: 'Mattias',
        lastName: 'Bylin',
        createdAt: '2022-01-26T16:17:32.591Z',
        id: '61f1741ca4b6200f4773f8f4'
    },
    logistics: {
        compnayName: "Bylin SAS",
        phone: "3214912185",
        title: 'Jefe de Logistica',
        email: 'pandalabco@gmail.com',
        firstName: 'Mattias',
        lastName: 'Bylin',
        createdAt: '2022-01-26T16:17:32.591Z',
        id: '61f1741ca4b6200f4773f8f4'
    },
    supervisor: {
        compnayName: "Bylin SAS",
        phone: "3214912185",
        title: 'Jefe de Logistica',
        email: 'pandalabco@gmail.com',
        firstName: 'Mattias',
        lastName: 'Bylin',
        createdAt: '2022-01-26T16:17:32.591Z',
        id: '61f1741ca4b6200f4773f8f4'
    },
    instaladores: [
        {
            compnayName: "Bylin SAS",
            phone: "3214912185",
            title: 'Jefe de Logistica',
            email: 'pandalabco@gmail.com',
            firstName: 'Mattias',
            lastName: 'Bylin',
            createdAt: '2022-01-26T16:17:32.591Z',
            id: '61f1741ca4b6200f4773f8f4'
        },
        {
            compnayName: "Bylin SAS",
            phone: "3214912185",
            title: 'Jefe de Logistica',
            email: 'pandalabco@gmail.com',
            firstName: 'Mattias',
            lastName: 'Bylin',
            createdAt: '2022-01-26T16:17:32.591Z',
            id: '61f1741ca4b6200f4773f8f4'
        },
        {
            compnayName: "Bylin SAS",
            phone: "3214912185",
            title: 'Jefe de Logistica',
            email: 'pandalabco@gmail.com',
            firstName: 'Mattias',
            lastName: 'Bylin',
            createdAt: '2022-01-26T16:17:32.591Z',
            id: '61f1741ca4b6200f4773f8f4'
        }
    ],
    movimientos: [
        {
            status: "Ejecutado",
            date: "2022-02-08T19:58:12.300Z",
            file: null,
            project: 
            {
                compnayName: "Bylin SAS",
                phone: "3214912185",
                title: 'Jefe de Logistica',
                email: 'pandalabco@gmail.com',
                firstName: 'Mattias',
                lastName: 'Bylin',
                createdAt: '2022-01-26T16:17:32.591Z',
                id: '61f1741ca4b6200f4773f8f4'
            },
            obs: "",
            type: "Salida",
            origin: 
            {
                compnayName: "Bylin SAS",
                phone: "3214912185",
                title: 'Jefe de Logistica',
                email: 'pandalabco@gmail.com',
                firstName: 'Mattias',
                lastName: 'Bylin',
                createdAt: '2022-01-26T16:17:32.591Z',
                id: '61f1741ca4b6200f4773f8f4'
            },
            destination: 
            {
                compnayName: "Bylin SAS",
                phone: "3214912185",
                title: 'Jefe de Logistica',
                email: 'pandalabco@gmail.com',
                firstName: 'Mattias',
                lastName: 'Bylin',
                createdAt: '2022-01-26T16:17:32.591Z',
                id: '61f1741ca4b6200f4773f8f4'
            },
            stock: [
              {
                _id: "6202ce21056a5f195b9e0de7",
                ref: "3040432",
                num: "3200101506",
                total: 50,
                refId: "61f92889ebe08438b8963701",
                itemId: "6202cb6a056a5f195b9e0dd7"
              },
              {
                _id: "6202ce21056a5f195b9e0de8",
                ref: "3040432",
                num: "3200101507",
                total: 20,
                refId: "61f92889ebe08438b8963701",
                itemId: "6202cb6a056a5f195b9e0dd9"
              }
            ],
            createdAt: "2022-02-08T20:10:09.660Z",
            updatedAt: "2022-02-08T20:10:09.660Z",
            movement_consec: 21015,
            id: "6202ce21056a5f195b9e0de6"
          },
        {
            status: "Ejecutado",
            date: "2022-02-08T19:58:12.300Z",
            file: null,
            project: 
            {
                compnayName: "Bylin SAS",
                phone: "3214912185",
                title: 'Jefe de Logistica',
                email: 'pandalabco@gmail.com',
                firstName: 'Mattias',
                lastName: 'Bylin',
                createdAt: '2022-01-26T16:17:32.591Z',
                id: '61f1741ca4b6200f4773f8f4'
            },
            obs: "",
            type: "Entrada",
            origin: 
            {
                compnayName: "Bylin SAS",
                phone: "3214912185",
                title: 'Jefe de Logistica',
                email: 'pandalabco@gmail.com',
                firstName: 'Mattias',
                lastName: 'Bylin',
                createdAt: '2022-01-26T16:17:32.591Z',
                id: '61f1741ca4b6200f4773f8f4'
            },
            destination: 
            {
                compnayName: "Bylin SAS",
                phone: "3214912185",
                title: 'Jefe de Logistica',
                email: 'pandalabco@gmail.com',
                firstName: 'Mattias',
                lastName: 'Bylin',
                createdAt: '2022-01-26T16:17:32.591Z',
                id: '61f1741ca4b6200f4773f8f4'
            },
            stock: [
              {
                _id: "6202cb69056a5f195b9e0dd5",
                ref: "3040432",
                num: "3200101506",
                total: 10,
                refId: "61f92889ebe08438b8963701",
                itemId: "6202cb6a056a5f195b9e0dd7"
              },
              {
                _id: "6202cb69056a5f195b9e0dd6",
                ref: "3040432",
                num: "3200101507",
                total: 0,
                refId: "61f92889ebe08438b8963701",
                itemId: "6202cb6a056a5f195b9e0dd9"
              }
            ],
            createdAt: "2022-02-08T19:58:33.666Z",
            updatedAt: "2022-02-08T19:58:35.060Z",
            movement_consec: 21013,
            id: "6202cb69056a5f195b9e0dd4"
          }
    ],
    value: 25000000,
    obs: '',
    progress: [
        {
            title: 'Inicio',
            subTitle: new Date().toISOString(),
            description: 'Cotizado',
            file: null,
        },
        {
            title: 'Cotizado',
            subTitle: new Date().toISOString(),
            description: 'Cotizado',
            file: null,
        },
        {
            title: 'Salida',
            subTitle: new Date().toISOString(),
            description: 'Despachado con salida 21015',
            file: null,
        },
        {
            title: 'Instalación',
            subTitle: new Date().toISOString(),
            description: 'Despachado con salida 21015',
            file: null,
        },
        {
            title: 'Obra entregada',
            subTitle: new Date().toISOString(),
            description: 'Despachado con salida 21015',
            file: null,
        },
        {
            title: 'Solicitud Recolección',
            subTitle: new Date().toISOString(),
            description: 'Despachado con salida 21015',
            file: null,
        },
        {
            title: 'Entrada',
            subTitle: new Date().toISOString(),
            description: 'Ingreso con entrada 21013',
            file: null,
        }
    ]
}