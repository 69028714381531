import React from 'react';
import * as Icons from '@ant-design/icons';
import { StyledIconMainButton } from './style';

export const IconMenuButton = ({ label, iconName }) => {
    const IconComponent = Icons[iconName];
    return (
        <StyledIconMainButton>
            <IconComponent />
            <h1>{label}</h1>
        </StyledIconMainButton>
    )
};
