import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PrivateRoute } from '../components/molecules/private-route';
import { PrincipalSideMenu } from '../components/organims/principal-side-menu';
import { MainLayout } from '../components/templates/main-layout';
import { getConfig } from '../store/config/config.actions';
import { privateListRoutes } from './private-route-list';
import { publicListRoutes } from './public-route-list';
import { getUserData } from '../store/auth/auth.selectors';

export const AppRoutes = () => {
  const dispatch = useDispatch();
  const { language } = useSelector(getUserData);
  React.useEffect(() => {
    dispatch(getConfig());
  }, [dispatch]);
  const t = useTranslation();
  useEffect(() => {
    t.i18n.changeLanguage(language || 'es');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])
  
  return (
    <Switch>
      {publicListRoutes.map((params, index) => (
        <PrivateRoute {...params} key={index} exact />
      ))}
      <MainLayout sideMenu={<PrincipalSideMenu />}>
        {privateListRoutes.map((params, index) => (
          <PrivateRoute {...params} key={index} exact />
        ))}
      </MainLayout>
    </Switch>
  );
};
